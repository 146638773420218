import React from 'react';
import { ChangeButtonSecond } from '../../UI/ChangeButtonSecond/ChangeButtonSecond';
import { ChangeButtonPrincipal } from '../../UI/ChangeButtonPrincipal/ChangeButtonPrincipal';

export const ChangeInformationDashboard = ({data, buttonInformation}) => {
  const {buttonWidth1, buttonWidth2, buttonText1, buttonText2, stateUser, setStateUser, stateAdministrator, setStateAdministrator } = buttonInformation
  return (
    <div className="w-[80%] flex flex-col items-end h-14">
      <div className={`flex justify-evenly w-full`}>
        <ChangeButtonPrincipal size={buttonWidth1} text={buttonText1} statePrincipal={stateUser} setStatePrincipal={setStateUser} setStateSecond={setStateAdministrator}/>
        <ChangeButtonSecond size={buttonWidth2} text={buttonText2}  stateSecond={stateAdministrator} setStateSecond={setStateAdministrator} setStatePrincipal={setStateUser}/>
      </div>
      <div className='w-[90%] flex justify-end items-center'>
        <p>Total de {`${stateUser === 'active' ? buttonText1: buttonText2}`}: {data.length}</p>
      </div>
    </div>
  )
}