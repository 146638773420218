/* eslint-disable react-hooks/exhaustive-deps */
import { styles } from '../../styles';
import React, {useState, useEffect, useContext} from 'react';
import { url } from '../../ApiRoutes';
import axios from 'axios';
import { userContext } from '../../UserProvider/UserProvider';
import Swal from 'sweetalert2';

export const UpdateGood = ({updateData}) => {
  
  const {user} = useContext(userContext);
  const [optionMode1, setOptionMode1] = useState('');
  const [optionMode2, setOptionMode2] = useState('');
  const [optionGoodState1, setOptionGoodState1] = useState('');
  const [optionGoodState2, setOptionGoodState2] = useState('');
  const [optionGoodState3, setOptionGoodState3] = useState('');
  const [description, setDescription] = useState(updateData.classDescription);
  const [element, setElement] = useState(updateData.element);
  const [atributtes, setAtributtes] = useState(updateData.atributtes);
  const [purchasingValue, setPurchasingValue] = useState(updateData.purchasingValue);
  const [goodState, setGoodState] = useState(updateData.goodStateId);
  const [mode, setMode] = useState(updateData.modeId);
  const [document, setDocument] = useState(updateData.document);
  const [currentLocation, setCurrentLocation] = useState(updateData.currentLocation);
  const [number, setNumber] = useState(updateData.number);

  const modeName = (modeId) => {
    if(modeId === 'M01'){
      setOptionMode1('Activo')
      setOptionMode2('Inactivo')
    }
    else if(modeId === 'M02'){
      setOptionMode1('Inactivo')
      setOptionMode2('Activo')
    }
  }

  const goodStateName = (goodStateId) => {
    if(goodStateId === 'GS01'){
      setOptionGoodState1('Bueno')
      setOptionGoodState2('Regular')
      setOptionGoodState3('Malo')
    }
    if(goodStateId === 'GS02'){
      setOptionGoodState1('Regular')
      setOptionGoodState2('Bueno')
      setOptionGoodState3('Malo')
    }
    if(goodStateId === 'GS03'){
      setOptionGoodState1('Malo')
      setOptionGoodState2('Bueno')
      setOptionGoodState3('Regular')
    }
  }

  const config = {
    headers: { Authorization: `Bearer ${user.data.token}` }
  };

  useEffect(()=>{
    modeName(updateData.modeId)
    goodStateName(updateData.goodStateId)
  },[])

  const updateGood = () => {
    Swal.fire({title: '¿Esta seguro?', text:'Se aplicaran los cambios' ,showDenyButton: true,showCancelButton: true,confirmButtonText: 'Save',denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios.put(url.UpdateGood+updateData.labels,{
          "classDescription": description,
          "element": element, 
          "atributtes": atributtes,
          "purchasingValue":purchasingValue,
          "goodStateId": goodState, 
          "modeId": mode,
          "document": document,
          "currentLocation" : currentLocation,
          "number": number
        }, config)
        .then(response => {
          Swal.fire('Saved!', '', 'success')
        })
      }
      else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }

  return (
    <div className="w-full h-screen flex justify-between flex-col  items-center">
      <div className={`${styles.titleContainer}`}>
        <h1 className={`${styles.title}`}>Actualizar bien</h1>
      </div>
      <div className={`${styles.formContainer} sm:flex-row flex-col`}>
        <div className='flex flex-col h-[25rem] justify-evenly'>
          <div className='flex flex-col'>
            <label className='text-[1.2rem]'>Descripción</label>
            <input className={`${styles.input}`} type="text" placeholder={updateData.classDescription} onChange={(e) => {setDescription(e.target.value)}}/>
          </div>
          <div className='flex flex-col'>
            <label className='text-[1.2rem]'>Elemento</label>
            <input className={`${styles.input}`} type="text" placeholder={updateData.element} onChange={(e) => {setElement(e.target.value)}}/>
          </div>
          <div className='flex flex-col'>
            <label className='text-[1.2rem]'>Atributos</label>
            <input className={`${styles.input}`} type="text" placeholder={updateData.atributtes} onChange={(e) => {setAtributtes(e.target.value)}}/>
          </div>
          <div className='flex flex-col'>
            <label className='text-[1.2rem]'>Valor Adquisitivo</label>
            <input className={`${styles.input} ${purchasingValue < 0 ? "border-red-600" : ""}`} type="number" placeholder={updateData.purchasingValue} onChange={(e) => {setPurchasingValue(e.target.value)}}/>
          </div>
          <div className='flex flex-col'>
            <label className='text-[1.2rem]'>Estado del bien</label>
            <select name="goodState" className={`${styles.input}`} onChange={(e) => {setGoodState(e.target.value)}}>
              <option value={optionGoodState1==='Bueno' ? 'GS01': optionGoodState1==='Regular' ? 'GS02' : 'GS03'} selected>{optionGoodState1}</option>
              <option value={optionGoodState2==='Bueno' ? 'GS01': optionGoodState2==='Regular' ? 'GS02' : 'GS03'}>{optionGoodState2}</option>
              <option value={optionGoodState3==='Bueno' ? 'GS01': optionGoodState3==='Regular' ? 'GS02' : 'GS03'}>{optionGoodState3}</option>
            </select>
          </div>
        </div>
        <div className='flex flex-col h-[25rem] justify-evenly'>
          <div className='flex flex-col'>
            <label className='text-[1.2rem]'>Estado</label>
            <select name="mode" className={`${styles.input}`} onChange={(e) => {setMode(e.target.value)}}>
              <option value={optionMode1==='Activo'? 'M01':'M02'} selected>{optionMode1}</option>
              <option value={optionMode2==='Activo'? 'M01':'M02'}>{optionMode2}</option>
            </select>
          </div>
          <div className='flex flex-col'>
            <label className='text-[1.2rem]'>Documento cuentadante</label>
            <input className={`${styles.input}`} type="text" placeholder={updateData.document} onChange={(e) => {setDocument(e.target.value)}}/>
          </div>
          <div className='flex flex-col'>
            <label className='text-[1.2rem]'>Ubicación Actual</label>
            <input className={`${styles.input}`} type="text" placeholder={updateData.currentLocation} onChange={(e) => {setCurrentLocation(e.target.value)}}/>
          </div>
          <div className='flex flex-col'>
            <label className='text-[1.2rem]'>Número ambiente</label>
            <input className={`${styles.input}`} type="number" placeholder={updateData.number} onChange={(e) => {setNumber(e.target.value)}}/>
          </div>
        </div>
      </div>
      <div className={`${styles.formButtonContainer}`}>
        <button className={`${styles.formButton}`} onClick={updateGood}>Actualizar</button>
      </div>
    </div>
  )
}
