/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from 'axios'
import React, {useState} from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import Swal from 'sweetalert2'
import { url } from '../../Components/ApiRoutes'
import { Dashboard } from '../../Components/Layout/Dashboard/Dashboard';
import { Header } from '../../Components/Layout/Header/Header'
import { RegisterUpdate } from '../../Components/Layout/RegisterUpdate/RegisterUpdate';
import { styles } from '../../Components/styles';
import { FooterHome } from '../../Components/UI/FooterHome/FooterHome'
import { HeaderDashboard } from '../../Components/UI/HeaderDashboard/HeaderDashboard';
import { UpdateInformation } from '../../Components/UI/UpdateInformation/UpdateInformation'
import { userContext } from '../../Components/UserProvider/UserProvider'
import {MagnifyingGlass} from "@vectopus/atlas-icons-react";


export const Updates = () => {

  const [updateData,setUpdateData] = useState([]);
  const {user} = useContext(userContext);
  const [optionData,setOptionData] = useState('1');
  const [roomsNumbers, setRoomsNumbers] = useState([]);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('');
  const [active, setActive] = useState('');
  const [message, setMessage] = useState('');


  const config = {
    headers: { Authorization: `Bearer ${user.data.token}` }
  };
  
  const roomsNumber = (() => {
    axios.get(url.RoomsNumbers, config)
    .then(response => {
      setRoomsNumbers(response.data.listRooms)
    })
    .catch(ex => {
      console.log(ex);
    })
  })

  const setNumberOption = ((event)=>{
    setOptionData(event.target.value)
  })

  const getUpdates = () => {
    if (filter === 'Cuentadante') {
      axios.post(url.UpdateFilter + 1,{
        'document':search
      }, config)
      .then(response => {
        if(response.data.updates.length === 0){
          setMessage('El cuentadante no ha generado novedades')
        } else {
          setUpdateData(response.data.updates)
          setMessage('');
        };
      })
      .catch(ex => {
        if(ex.response.data.error === 'USER_NOT_EXIST'){
          setMessage('El cuentadante no existe')          
        }
      })
    }
    else if (filter === 'Fecha') {
      axios.post(url.UpdateFilter + 2,{
        'date':search
      }, config)
      .then(response => {
        if (response.data.updates.length === 0) {
          setMessage('No hay novedades de esta esta fecha')
        } else {
          setUpdateData(response.data.updates)
          setMessage('')
        }
      })
      .catch(ex => {
        console.log(ex.response.data.error);
      })
    }
  }

  const headerInformation = {
    names: 'Gestionar',
    lastNames: 'Novedades',
		links: [
      {
        url:'/',
        name: 'Inicio'
      },
			{
				url: '',
				name: 'Novedades',
			},
      {
				url: '',
				name: 'Generar Novedades',
			}
		]
  }

  const sizeColumn = {
    column1 : "5rem",
    column2 : "6rem",
    column3 : "7rem",
    column4 : "5rem",
    column5 : "2rem",
    column6 : "7rem",
    column7 : "4rem",
  }

  const headerDashboardInformation = {
    columnText1 : "Numero",
    columnText2 : "Creador",
    columnText3 : "Descripción",
    columnText4 : "Fecha",
    columnText5 : "Hora",
    columnText6 : "Acciones",
    columnWidth1 : sizeColumn.column1,
    columnWidth2 : sizeColumn.column2,
    columnWidth3 : sizeColumn.column3,
    columnWidth4 : sizeColumn.column4,
    columnWidth5 : sizeColumn.column5,
    columnWidth6 : sizeColumn.column6,
  }

  useEffect(()=> {
    roomsNumber()
  },[])

  const keyUp=(event)=>{
    if (event.keyCode===13) {
      if(search === ''){
        Swal.fire({title: '¡Error!',text: 'Campo vacío',icon: 'error',confirmButtonText: 'Ok'})
      } else {
        setActive('');
        getUpdates()
      }
    }
  }

  useEffect(()=>{
    getUpdates();
	},[search])

  const setFocus = e => {
    e.target && e.target.focus();
  };

  return (
    <>    
    <div>
      <div className='sm:w-[100%]'> 
        <Header data={headerInformation} setNumber={setNumberOption} />
      </div>
      <div className='sm:w-[100%] w-full flex justify-center items-center flex-col'>
        <div className={`${styles.mainContainer}`}>
          {optionData === '1' && 
          <>
            <div className='w-[57rem] flex items-center gap-[2rem] flex-col'>
              <div className={`w-[57rem] flex justify-center gap-1 relative`}>
                <input type="search" className={`${filter===''? "pl-[2.5rem]" : "pl-[11rem]"} h-[3rem] w-[57rem] border-2 border-[#e7ebda] rounded outline-none bg-[#f4f4f4] box-border`} placeholder='Buscar novedades' onClick={()=>setActive('active')} onChange={(e) =>{setSearch(e.target.value)}} onBlur={(e) => setFocus(e)} onKeyUp={(e=>keyUp(e))}/>
                <MagnifyingGlass className='absolute left-[0.75rem] top-[50%] translate-y-[-50%]' size={18} color={'#515151'}/>
                <p className="absolute top-[50%] left-[2.8rem] translate-y-[-50%] font-semibold bg-[#dcdcdc] flex justify-evenly items-center w-[8rem] rounded">{filter} <svg xmlns="http://www.w3.org/2000/svg" className={`${filter===''? "hidden": "icon icon-tabler icon-tabler-x cursor-pointer" }`} onClick={()=>{setFilter('')}} width="18" height="18" viewBox="0 0 24 24" stroke="#ff2825" fill="none"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><line x1="18" y1="6" x2="6" y2="18" /><line x1="6" y1="6" x2="18" y2="18" /></svg></p>
              </div>
              <div className={`${active==='active' ? "flex w-[58rem] h-[3rem] items-center justify-evenly" : "hidden"}`}>
                <p className='font-semibold bg-[#dcdcdc] rounded w-[8rem] text-center p-[0.3rem] cursor-pointer' id='Cuentadante' onClick={(e)=>{setFilter(e.target.id); setActive('')}}>Cuentadante</p>
                <p className='font-semibold bg-[#dcdcdc] rounded w-[8rem] text-center p-[0.3rem] cursor-pointer' id='Fecha' onClick={(e)=>{setFilter(e.target.id); setActive('')}}>Fecha</p>
              </div>
            </div>
            <div className='w-full flex items-center gap-[2rem] flex-col'>
              <HeaderDashboard headerDashboardInformation={headerDashboardInformation}/>
              {
                <Dashboard dashboardInformation={
                  search === '' ? <p className='font-semibold text-lg'>No se ha generado una búsqueda</p> : message === '' ? updateData?.map((update, index) => <UpdateInformation  updateInformation={update}/>) : <p className='font-semibold text-lg'>{message}</p> 
              }/>
              }
            </div>
          </>
          }
          {optionData === '2' && 
            <RegisterUpdate/>          
          }
          </div>
        <div className='w-full'>
          <FooterHome/>
        </div>
      </div>
    </div>
    </>
  )
}
