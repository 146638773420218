import axios from 'axios';
import './RecoverPassword.css';
import React, { useState } from 'react'
import { decodeToken } from 'react-jwt';
import { url } from '../../ApiRoutes';
import { useNavigate, useSearchParams } from 'react-router-dom';
import greenlogo from '../../assets/greenlogo.jpg'
import Swal from 'sweetalert2';

export const RecoverPassword = () => {

  let password = '';
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  let emailToken = searchParams.get('token');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const token = decodeToken(emailToken);

  const resetPassword = () => {
    axios.post(url.ResetPassword,{
        "email": token.email,
        "password": password
    })
    .then(response => {
      if(response.data === 'SUCESSFUL_CHANGE'){
        navigate('/login');
        window.location.reload();
      } else {
        Swal.fire({title: 'Error!', text: 'Su contraseña no se ha podido cambiar', icon:'error', confirmButtonText: 'Ok'})
      }
    })
  }

  const validatePassword = () => {
    if (newPassword === confirmPassword) {
      password = newPassword;
      resetPassword()
    } else {
      Swal.fire({title: 'Error!', text: 'Las contraseñas no coinciden', icon:'error', confirmButtonText: 'Ok'})
    }
  }

  const keyUp=(event)=>{
    if (event.keyCode===13) {
      validatePassword()
    }
  }  

  return (
    <div className='bg-gradient-to-r from-[#2F7A25] w-full h-screen to-[rgb(23,199,0)] shadow flex justify-center items-center flex-col '>
        <div className='bg-white  w-[500px] h-[600px]  rounded-md  flex justify-between items-center flex-col container_recover_app '>
            <div className='mt-10  w-[10rem] h-[10rem] container_img '>
                <img src={greenlogo} alt="logo_inventario"  className='rounded  '/>
            </div>
            <div >
                <h1 className='text-[2rem]  title'>Recuperar Contraseña</h1>
            </div>
            <div className='w-[70%] flex flex-col gap-3 justify-center items-center'>
                <label>Ingresa tu nueva contraseña:</label>
                <input type="password" placeholder='Ingrese la contraseña nueva' className='w-full rounded-b-md border-solid border-green-600 border-2  p-1  input' onChange={(e)=>{setNewPassword(e.target.value)}} />
                <label>Confirma tu nueva contraseña:</label>
                <input type="password" placeholder='confirme su contraseña ' className='w-full rounded-b-md border-solid border-green-600 border-2  p-1  input' onChange={(e)=>{setConfirmPassword(e.target.value)}} onKeyUp={(e) => keyUp(e)}/>
            </div>
            <div className='flex justify-between gap-[2rem] p-2' >
              <button className='bg-green-500 p-[5px_30px] rounded text-white text-[14px]' onClick={validatePassword}>Cambiar</button>
            </div>
        </div>
    </div>
  )
}
