/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { decodeToken } from 'react-jwt';
import { Header } from '../../Layout/Header/Header';
import { FooterHome } from '../../UI/FooterHome/FooterHome';
import React, { useState,useContext, useEffect  } from 'react';
import { Dashboard } from '../../Layout/Dashboard/Dashboard';
import { userContext } from '../../UserProvider/UserProvider';
import { url } from '../../ApiRoutes';
import { UserBasicData } from '../../Layout/UserBasicData/UserBasicData';
import { HeaderDashboard } from '../../UI/HeaderDashboard/HeaderDashboard';
import { GoodInformation } from '../../UI/GoodInformation/GoodInformation';
import { UpdateUserData } from '../../Layout/UpdateUserData/UpdateUserData';
import { UpdateUserPassword } from '../../Layout/UpdateUserPassword/UpdateUserPassword';

export const Profile = () => {
  
  const [optionData,setOptionData]=useState('0')
  const [rol, setRol]=useState('0')
  const [data, setData] = useState([])
  const [goodData,setGoodData] = useState([])
	const {user} = useContext(userContext)
  const tokenData = decodeToken(user.data.token)

  const config = {
    headers: { Authorization: `Bearer ${user.data.token}` }
};

  const getUserData = (()=> {
    axios.get(url.UserAllData+tokenData.userName, config)
    .then(response =>{
      setData(response.data.users[0]);
    })
    .catch(ex=>{
      console.log('ERROR '+ ex);
    })
  })
  
  const getUsersGoods = (() => {
    axios.get(url.GetUsersGood+tokenData.userName, config)
    .then(response => {
      setGoodData(response.data.goods)
    })
  })

  useEffect(()=>{
    getUserData()
    getUsersGoods()
  },[''])
  
  const setNumberOption = ((event)=>{
    setOptionData(event.target.value)
  })

  const headerProfileInfo = {
    img : data.photo,
		imgAlt: 'UserImg',
    imgStyles: 'rounded-[100%] sm:w-[70%] lg:w-[60%]',
    names: data.names,
    lastNames: data.lastNames,
		links: [
			{
				url: '',
				name: 'Datos básicos',
			},
			{
				url: '',
				name: 'Actualizar datos',
			},
			{
				url: '',
				name: 'Actualizar contraseña',
			},
			{
				url: '',
				name: 'Mis bienes',
			}
		]
  }

  const headerConciergeProfileInfo = {
    img : data.photo,
		imgAlt: 'UserImg',
    imgStyles: 'rounded-[100%] sm:w-[70%] lg:w-[60%]',
    names: data.names,
    lastNames: data.lastNames,
		links: [
			{
				url: '',
				name: 'Datos básicos',
			},
			{
				url: '',
				name: 'Actualizar datos',
			},
			{
				url: '',
				name: 'Actualizar contraseña',
			},
      
		]
  }

  const sizeColumn = {
    column1 : "9rem",
    column2 : "4rem",
    column3 : "9rem",
    column4 : "6rem",
    column5 : "6rem",
    column6 : "2rem",
  }

  const headerDashboardInformation = {
    columnText1 : "Placa",
    columnText2 : "Elemento",
    columnText3 : "Descripción",
    columnText4 : "Atributos",
    columnText5 : "Valor",
    columnText6 : "Ubicación",
    columnWidth1 : sizeColumn.column1,
    columnWidth2 : sizeColumn.column2,
    columnWidth3 : sizeColumn.column3,
    columnWidth4 : sizeColumn.column4,
    columnWidth5 : sizeColumn.column5,
    columnWidth6 : sizeColumn.column6,
  }

  return (
    <div>
      <div className='sm:w-[100%] '>
        {rol === '3' ?  <Header data={headerConciergeProfileInfo} setNumber={setNumberOption} /> : <Header data={headerProfileInfo} setNumber={setNumberOption} />}
      </div>
      <div className='sm:w-[100%] w-full flex flex-col justify-center gap-[5rem] items-center'>
        <div className='w-[100%] flex justify-center items-center'>
        {optionData === '0' &&
            <UserBasicData data={data} />
        }
        {optionData === '1' &&
            <UpdateUserData data={data} />
        }
        {optionData === '2' &&
            <UpdateUserPassword/>
        }
        {optionData === '3' &&
            <>
            <div className='h-screen w-screen flex justify-center gap-5 items-center flex-col'>
              <h1 className='text-[2.6rem] font-semibold'>Mis bienes</h1>
              <HeaderDashboard headerDashboardInformation={headerDashboardInformation}/>
              <Dashboard dashboardInformation={
                goodData?.map(item=>
                  <GoodInformation goodInformation={item} sizeColumn={sizeColumn} key={item.labels}/>  
                  )}
              />
            </div>
            </>
        }
        </div>
        <div className='w-full'>
          <FooterHome/>
        </div>
      </div>
    </div>
)
}
