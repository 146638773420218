import React, { useEffect, useState } from 'react'
import {XmarkCircle} from "@vectopus/atlas-icons-react";
import { Dashboard } from '../../Layout/Dashboard/Dashboard';
import { GoodInformation } from '../GoodInformation/GoodInformation';
import { HeaderDashboard } from '../HeaderDashboard/HeaderDashboard';


export const UserModal = ({data,visible,onClose}) => {

  const [goods,setGoods] = useState()

  const close=()=>{onClose(false)}

  useEffect(()=>{
    setGoods(data.goods)
  },[''])

  if(!visible)return null;

  const sizeColumn = {
    column1 : "4rem",
    column2 : "7rem",
    column3 : "7rem",
    column4 : "7rem",
    column5 : "5rem",
    column6 : "5rem",
    column7 : "",
  }

  const headerDashboardInformation = {
    columnText1 : "Placa",
    columnText2 : "Elemento",
    columnText3 : "Descripción",
    columnText4 : "Atributos",
    columnText5 : "Valor",
    columnText6 : "Ubicación",
    columnWidth1 : sizeColumn.column1,
    columnWidth2 : sizeColumn.column2,
    columnWidth3 : sizeColumn.column3,
    columnWidth4 : sizeColumn.column4,
    columnWidth5 : sizeColumn.column5,
    columnWidth6 : sizeColumn.column6,
    columnWidth7 : sizeColumn.column7,
  }

return (
  <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex items-center justify-center z-10">
    <div className='md:w-auto w-[90%] h-[35rem] flex flex-col justify-evenly items-center rounded-[1rem] bg-[#ebecef]'>
      <div className='flex justify-end md:w-[65rem] w-[90%]'>
      <XmarkCircle className='cursor-pointer' size={22} color={'#ff2825'} onClick={close}/>
      </div>
      <div className='bg-[#fff] md:w-[55rem] w-[90%] h-[10rem] flex justify-around items-center rounded shadow-[0_0_10px_2px_rgba(204,204,204,1)]'>
        <div className='flex justify-center flex-col gap-1'>
          <p className='ml-1'><b>Cuentadante:</b> {data.names} {data.lastNames}</p>
          <p className='ml-1'><b>Documento:</b> {data.document}</p>
          <p className='ml-1'><b>Centro de formación:</b> </p>
        </div>
        <div className='flex justify-center flex-col gap-1'>
          <p className='ml-1'><b>Rol:</b> {data.role.roleName} </p>
          <p className='ml-1'><b>Cantidad de bienes: </b> {data.goods.length}</p>
          <p className='ml-1'><b>Valor total:</b> </p>
        </div>
      </div>
      <div className='h-[45%] md:w-[70rem] w-[100%] flex justify-center gap-[1rem] flex-col items-center'>
        <HeaderDashboard headerDashboardInformation={headerDashboardInformation} />
        <Dashboard dashboardInformation = {
          goods?.map((item, index) => 
            <GoodInformation  goodInformation={item} key={index} />
        )}/>
      </div>
    </div>
  </div>
)
}