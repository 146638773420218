import React from 'react';

export const HeaderDashboard = ({headerDashboardInformation}) => {
  return (
    <div className='w-[84%] h-[3rem] flex justify-center bg-[#5e5e5e] rounded'>
      <div className='flex w-[95%] justify-around items-center gap-[1rem]'>  
        <p className={`w-[${headerDashboardInformation.columnWidth1}] font-semibold text-center text-white`}>{headerDashboardInformation.columnText1}</p>
        <p className={`w-[${headerDashboardInformation.columnWidth2}] font-semibold text-center text-white`}>{headerDashboardInformation.columnText2}</p>
        <p className={`w-[${headerDashboardInformation.columnWidth3}] font-semibold text-center text-white`}>{headerDashboardInformation.columnText3}</p>
        <p className={`w-[${headerDashboardInformation.columnWidth4}] font-semibold text-center text-white`}>{headerDashboardInformation.columnText4}</p>
        <p className={`w-[${headerDashboardInformation.columnWidth5}] font-semibold text-center text-white`}>{headerDashboardInformation.columnText5}</p>
        <p className={`w-[${headerDashboardInformation.columnWidth6}] font-semibold text-center text-white`}>{headerDashboardInformation.columnText6}</p>
        <p className={`w-[${headerDashboardInformation.columnWidth7}] font-semibold text-center text-white`}>{headerDashboardInformation.columnText7}</p>
      </div>
    </div>
  )
}
