/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import Swal from 'sweetalert2';
import { url } from '../../ApiRoutes';
import { Dashboard } from '../Dashboard/Dashboard';
import { userContext } from '../../UserProvider/UserProvider';
import React , { useState, useEffect, useContext } from 'react';
import { HeaderDashboard } from '../../UI/HeaderDashboard/HeaderDashboard';
import { GoodInformationAdmin } from '../../UI/GoodInformationAdmin/GoodInformationAdmin';
import { UpdateGood } from '../UpdateGood/UpdateGood';
import {ArrowLeft} from "@vectopus/atlas-icons-react";
import { InputSearch } from '../../UI/InputSearch/InputSearch';

export const RoomsGoods = () => {

  const {user} = useContext(userContext);
  const [goods,setGoods] = useState([]);
  const [roomNumber,setRoomNumber] = useState('');
  const [message, setMessage] = useState('');
  const [optionData,setOptionData] = useState('0');
  const [updateData, setUpdateData] = useState([])

  const config = {
    headers: { Authorization: `Bearer ${user.data.token}` }
};

  const getGoodsRooms = (word) => {
    if (word === undefined || word === ''){
      setMessage('No se ha generado una búsqueda');
    } else{
      if (word !== '') {
        axios.get(url.RoomsGoods+word,config)
        .then(response => {
          console.log(response);
          if(response.data.roomData[0].goods.length !== 0){
            setGoods(response.data.roomData[0].goods)
            setMessage('')
          } else if(response.data.roomData[0].goods.length === 0) {
            setMessage('El ambiente no tienes bienes asociado');
          }
        })
        .catch(ex => {
          console.log(ex.response.data.error);
          if (ex.response.data.error ==='ROOM_NOT_EXIST') {
            setMessage('El ambiente no existe');
          } 
        })
      }
    }
  }

  const sizeColumn = {
    column1 : "5rem",
    column2 : "6rem",
    column3 : "7rem",
    column4 : "5rem",
    column5 : "2rem",
    column6 : "7rem",
    column7 : "4rem",
  }

  const headerDashboardInformation = {
    columnText1 : "Placa",
    columnText2 : "Elemento",
    columnText3 : "Descripción",
    columnText4 : "Atributos",
    columnText5 : "Valor",
    columnText6 : "Ubicación",
    columnText7 : "Acciones",
    columnWidth1 : sizeColumn.column1,
    columnWidth2 : sizeColumn.column2,
    columnWidth3 : sizeColumn.column3,
    columnWidth4 : sizeColumn.column4,
    columnWidth5 : sizeColumn.column5,
    columnWidth6 : sizeColumn.column6,
    columnWidth7 : sizeColumn.column7,
  }

  const setDataToUpdate = ((e,index)=> {
    setUpdateData(goods[index]);
    setRoomNumber('')
    setOptionData('2');
  })
  
  useEffect(()=>{
    getGoodsRooms();
	},[roomNumber])

  return (
    <div className='w-full h-[50rem] flex justify-between items-center flex-col'>
      <div className={`${optionData === '2' ? 'hidden' : 'flex'} w-[84%] flex justify-around`}>
        <InputSearch type='number' options={[]} placeholder='Número de ambiente' searchMethod={getGoodsRooms}/>
      </div>
      <div className='w-[80%] flex justify-end'>
        <button className={`${optionData === '0' ? 'hidden' : 'flex'} gap-[0.5rem] h-[3em] w-24 items-center justify-center bg-[#0505054b] rounded-[3px] cursor-pointer border-none`} onClick={(e)=>setOptionData('0')}><ArrowLeft size={20}/>
        <span>Volver</span></button>
      </div>
      <div className="w-full h-auto gap-4 flex flex-col items-center">
        {optionData === '0' &&
          <>
            <HeaderDashboard headerDashboardInformation={headerDashboardInformation}/>
              <Dashboard dashboardInformation={
                message === '' ? goods?.map((good, index) => <GoodInformationAdmin goodInformation={good} updateData={setDataToUpdate} index={index} key={index} id={index}/>) : <p className='font-semibold text-lg'>{message}</p>
              }/>
          </>
        }
        {optionData === '2' && 
          <UpdateGood updateData={updateData}/>
        }
      </div>
    </div>
  )
}