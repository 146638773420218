/* eslint-disable no-unused-vars */
import axios from 'axios';
import Swal from 'sweetalert2';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {Account, PadlockDots} from "@vectopus/atlas-icons-react";
import logotipoInventario from '../../assets/LogotipoInventario.png';
import logoSenaVerde from '../../assets/logoSenaVerde.png';


export const LoginForm = () => {

  const [userName, setUserName]=useState("")
  const [password, setPassword]= useState("")
  const [user,setUser]=useState({})
  const [token, setToken]=useState('')

  const navigate = useNavigate()

  const setEventToUserName=((event) =>{
    setUserName(event.target.value)
  })

  const setEventToPassword =((event) =>{
    setPassword(event.target.value)
  })

const login = (() =>{
  if(userName !== "" && password !== "" ){
    axios.post(`http://localhost:3308/api/users/login?userName=${userName}&password=${password}`)
    .then(response => {
      setUser(response.data)
      setToken(response.data.data.token)
      localStorage.setItem('userInfo', JSON.stringify(response.data))
      navigate('/')
      window.location.reload()
    })
    .catch(ex =>{
      if (ex.response.data.error === 'USER_NOT_EXIST') {
        Swal.fire({title: 'Error!',text: 'Nombre de usuario incorrecto',icon: 'error',confirmButtonText: 'Ok'})
      }
      if (ex.response.data.error === 'PASSWORD_INVALID') {
        Swal.fire({title: 'Error!',text: 'Contraseña incorrecta',icon: 'error',confirmButtonText: 'Ok'})
      }
    })
  }
  else{
    Swal.fire({title: 'Error!',text: 'Campos Vacios',icon: 'error',confirmButtonText: 'Ok'})
  }
})

const keyUpLogin=(event)=>{
  if (event.keyCode===13) {
    login()
  }
}

return (
  <div className='md:w-[35rem] w-[100%] h-[30rem] flex flex-col justify-end gap-[2.5rem] items-center rounded-l-md bg-slate-100'>
    <div className='flex flex-col justify-between h-[60%] w-[90%]'>
        <div className='flex justify-center'>
          <Link to='/'><img className='h-[60%]' src={logotipoInventario} alt="Logotipo del inventario SENA" /></Link>
        </div>
      <div className='flex flex-col justify-between items-center'>
        <div className='w-[100%]'>
          <label className='text-[1.3rem]'>Nombre de usuario</label>
          <div className='relative'>
            <input type="text" required className='w-[100%] h-[2rem] text-[1.2rem] rounded-b-md border-solid pl-[2.5rem] border-green-600 border-2' placeholder='Nombre de usuario' onChange={setEventToUserName}/>
            <Account className='absolute left-[0.75rem] top-[50%] translate-y-[-50%]' size={18} color={'#515151'}/>
          </div>
        </div>
        <div className='w-[100%] flex flex-col'>
          <label className='text-[1.3rem]' >Contraseña</label>
          <div className='relative'>
            <input type="password" required minLength='8' className='w-[100%] h-[2rem] text-[1.2rem] rounded-b-md pl-[2.5rem] border-solid border-green-600 border-2' placeholder='Contraseña' onChange={setEventToPassword} onKeyUp={e => keyUpLogin(e)} />
            <PadlockDots className='absolute left-[0.75rem] top-[50%] translate-y-[-50%]' size={18} color={'#515151'}/>
          </div>
        </div>
      </div>
      <div className='flex justify-center flex-col'>
          <Link to='/RememberPassword'><p className='text_forget_password'>¿Olvidaste tu contraseña?</p></Link>
      </div>
      <div className='flex justify-center'>
      <button className='btn hover:bg-green-600 border-md border-solid border-black' onClick={login}>Iniciar sesión</button>
      </div>
    </div>
    <img className='h-[3.5rem] w-[3rem] pb-2' src={logoSenaVerde} alt="Logo del SENA" />
  </div>
)
}
