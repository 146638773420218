export const headerHomeInfo = {
  img : 'https://res.cloudinary.com/ditxfydru/image/upload/v1672062713/InventarioSena/greenlogo_pcg0mq.jpg',
  imgAlt: 'LogoInventarioSena',
  imgStyles: 'rounded sm:w-[70%] lg:w-[60%]',
  links: [
    
  ]
}

export const headerHomeInfoInstructor = {
  img : 'https://res.cloudinary.com/ditxfydru/image/upload/v1672062713/InventarioSena/greenlogo_pcg0mq.jpg',
  imgAlt: 'LogoInventarioSena',
  imgStyles: 'rounded sm:w-[70%] lg:w-[60%]',
  links: [
    {
      url: '/Rooms/User',
      name: 'Ambientes'
    },
    {
      url: `/Goods/User`,
      name: 'Bienes'
    },
    {
      url: '/Inventory/User',
      name: 'Inventario'
    },
    {
      url: '/Reports/User',
      name: 'Reportes'
    },
    {
      url: '/Updates/User',
      name: 'Novedades'
    }
  ]
}
export const headerHomeInfoAdmin = {
  img : 'https://res.cloudinary.com/ditxfydru/image/upload/v1672062713/InventarioSena/greenlogo_pcg0mq.jpg',
  imgAlt: 'LogoInventarioSena',
  imgStyles: 'rounded sm:w-[70%] lg:w-[60%]',
  links: [
    {
      url: '/ManageUsers',
      name: 'Gestionar Usuarios'
    },
    {
      url: '/ManageRooms',
      name: 'Gestionar Ambientes'
    },
    {
      url: `/ManageGoods/Admin`,
      name: 'Gestionar Bienes'
    },
    {
      url: '/ManageInventory',
      name: 'Gestionar Inventario'
    },
    {
      url: '/ManageReports',
      name: 'Gestionar Reportes'
    }
  ]
}

export const headerHomeInfoConcierge = {
  img : 'https://res.cloudinary.com/ditxfydru/image/upload/v1672062713/InventarioSena/greenlogo_pcg0mq.jpg',
  imgAlt: 'LogoInventarioSena',
  imgStyles: 'rounded sm:w-[70%] lg:w-[60%]',
  links: [
    {
      url: '/Rooms',
      name: 'Ambientes'
    },
    {
      url: '/Goods',
      name: 'Bienes'
    },
    {
      url: '/Inventory',
      name: 'Inventario'
    },
    {
      url: '/ManageReports',
      name: 'Reportes'
    }
  ]
}

