/* eslint-disable no-unused-vars */
import axios from 'axios'
import React, { useState, useContext } from 'react'
import Swal from 'sweetalert2'
import { url } from '../../Components/ApiRoutes'
import { Header } from '../../Components/Layout/Header/Header'
import { FooterHome } from '../../Components/UI/FooterHome/FooterHome'
import { InputReport } from '../../Components/UI/InputReport/InputReport'
import { userContext } from '../../Components/UserProvider/UserProvider'


export const ReportsInstructor = () => {

  const [optionData,setOptionData] = useState('0')
  const [userDocument,setUserDocument] = useState('')
  const [roomNumber, setRoomNumber] = useState('')
  const {user} = useContext(userContext);

  const setNumberOption = ((event)=>{
    setOptionData(event.target.value)
  })

  const setDocument = ((event) => {
    const value = event.target.value.replace(/\D/g, "");
    setUserDocument(value)
  })
  
  const setRoom = ((event) => {
    const value = event.target.value.replace(/\D/g, "");
    setRoomNumber(value)
  })


  const config = {
    headers: { Authorization: `Bearer ${user.data.token} `, 'Content-Type': 'multipart/form-data' }
  };

  const headerInformation = {
    names: 'Gestionar',
    lastNames: 'Reportes',
		links: [
      {
        url:'/',
        name: 'Inicio'
      },
			{
				url: '',
				name: 'Generar reportes',
			}
		]
  }

  const generateReportGoods = () => {
    if (userDocument !== '') {
      axios.get(url.GenerateReportGoods+userDocument, config )
      .then(response => {
        window.open(url.GenerateReportGoods+userDocument)
        setUserDocument('')
      })
      .catch(ex => {
        if (ex.response.data.error === 'USER_NOT_EXIST') {
          Swal.fire({title: '¡Error!',text: 'Este usuario no esta registrado',icon: 'error',confirmButtonText: 'Ok'
          })
        }
      })
    }
    else{
      Swal.fire({title: '¡Error!',text: 'Campo vacío',icon: 'error',confirmButtonText: 'Ok'
      })
    }
  }

  const validaNumericos = (event) => {
    if(event.charCode >= 48 && event.charCode <= 57){
      return true;
    }
      return false;        
}

  const generateReportRooms = () => {
    if (roomNumber !== '') {
      axios.get(url.GenerateReportRooms+roomNumber, config )
      .then(response => {
        window.open(url.GenerateReportRooms+roomNumber)
        setRoomNumber('')
      })
      .catch(ex => {
        if (ex.response.data.error === 'ROOM_NOT_EXIST') {
          Swal.fire({title: '¡Error!',text: 'Este ambiente no esta registrado',icon: 'error',confirmButtonText: 'Ok'
          })
        }
      })
    }
    else{
      Swal.fire({title: '¡Error!',text: 'Campo vacío',icon: 'error',confirmButtonText: 'Ok'
      })
    }
  }


  return (
    <div>
    <div className='sm:w-[100%]'> 
      <Header data={headerInformation} setNumber={setNumberOption} />
    </div>
    <div className='sm:w-[100%] w-full flex justify-center items-center flex-col'>
      <div className='w-[100%] h-[90vh] flex justify-center gap-[4rem] items-center flex-col'>
        <div className='w-[80%] h-[5rem]  flex justify-center items-center border-b-2'>
          <h1 className='text-[2.3rem]'>Gestionar reportes</h1>
        </div>
        <div className='w-[90%] h-[60%] flex justify-evenly items-center flex-col'>
          <InputReport reportName='Generar reporte de bienes por Cuentadante' reportPlaceholcer='Número de documento' setState={setDocument} generateReport={generateReportGoods} value={userDocument}/>
          <InputReport reportName='Generar reporte de bienes por Ambiente' reportPlaceholcer='Número de ambiente' onKeyPress={(e) => {return validaNumericos(e)}}  setState={setRoom} generateReport={generateReportRooms} value={roomNumber} />
        </div>
      </div>
      <div className='w-full'>
        <FooterHome/>
      </div>
    </div>
  </div>
  )
}
