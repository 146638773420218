/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'

export const UserBasicData = ({data}) => {

	const [employmentRelationship,setEmploymentRelationship]=useState('')
	const [rol,setRol]=useState('')


	useEffect(()=>{
		if (data.employmentRelationshipId === 'EM01') {
			setEmploymentRelationship('Administrativo')
		}
		else if (data.employmentRelationshipId === 'EM02') {
			setEmploymentRelationship('Instructor Contrato')
		}
		else if (data.employmentRelationshipId === 'EM03') {
			setEmploymentRelationship('Instructor Planta')
		}
		if (data.roleId === 'R01') {
			setRol('Administrador')
		}
		else if (data.roleId === 'R02') {
			setRol('Instructor')
		}
		else if (data.roleId === 'R03') {
			setRol('Conserje')
		}
	},[])
	
  return (
		<div className='w-full h-screen flex flex-col justify-center items-center' >
			<div className='w-[100%] flex justify-around items-center'>
				<h1 className='text-[2.5rem]'>Mis datos</h1>
				<img src={`${data.QR}`} alt="UserQR" />
			</div>
			<div className='w-[70%] h-[70%] grid grid-cols-2	justify-center items-center'>
				<div className='flex flex-col justify-center items-center'>
					<label className='text-[1.2rem] w-[80%]'>Nombres</label>
					<input type="text" className='w-[80%] h-[2.5rem] rounded-b-md border-solid border-green-600 border-2 pl-1' placeholder='Nombres' disabled value={data.names}/>
				</div>
				<div className='flex flex-col justify-center items-center'>
					<label className='text-[1.2rem] w-[80%]'>Apellidos</label>
					<input type="text" className='w-[80%] h-[2.5rem] rounded-b-md border-solid border-green-600 border-2 pl-1' placeholder='Apellidos' disabled value={data.lastNames} />
				</div>
				<div className='flex flex-col justify-center items-center'>
					<label className='text-[1.2rem] w-[80%]'>Numero de documento</label>
					<input type="text" className='w-[80%] h-[2.5rem] rounded-b-md border-solid border-green-600 border-2 pl-1' placeholder='Numero de documento' disabled value={data.document} />
				</div>
				<div className='flex flex-col justify-center items-center'>
					<label className='text-[1.2rem] w-[80%]'>Nombre de usuario</label>
					<input type="text" className='w-[80%] h-[2.5rem] rounded-b-md border-solid border-green-600 border-2 pl-1' placeholder='Nombre de usuario' disabled value={data.userName}/>
				</div>
				<div className='flex flex-col justify-center items-center'>
					<label className='text-[1.2rem] w-[80%]'>Dirección</label>
					<input type="text" className='w-[80%] h-[2.5rem] rounded-b-md border-solid border-green-600 border-2 pl-1' placeholder='Dirección' disabled value={data.homeAddress}/>
				</div>
				<div className='flex flex-col justify-center items-center'>
					<label className='text-[1.2rem] w-[80%]'>Numero de telefono</label>
					<input type="text" className='w-[80%] h-[2.5rem] rounded-b-md border-solid border-green-600 border-2 pl-1' placeholder='Numero de telefono' disabled value={data.phoneNumber} />
				</div>
				<div className='flex flex-col justify-center items-center'>
					<label className='text-[1.2rem] w-[80%]'>Numero de celular</label>
					<input type="text" className='w-[80%] h-[2.5rem] rounded-b-md border-solid border-green-600 border-2 pl-1' placeholder='Numero de celular' disabled value={data.cellPhoneNumber}/>
				</div>
				<div className='flex flex-col justify-center items-center'>
					<label className='text-[1.2rem] w-[80%]'>Correo</label>
					<input type="text" className='w-[80%] h-[2.5rem] rounded-b-md border-solid border-green-600 border-2 pl-1' placeholder='Correo' disabled value={data.email}/>
				</div>
				<div className='flex flex-col justify-center items-center'>
					<label className='text-[1.2rem] w-[80%]'>Rol</label>
					<input type="text" className='w-[80%] h-[2.5rem] rounded-b-md border-solid border-green-600 border-2 pl-1' placeholder='Rol' disabled value={rol}/>
				</div>
				<div className='flex flex-col justify-center items-center'>
					<label className='text-[1.2rem] w-[80%]'>Profesión</label>
					<input type="text" className='w-[80%] h-[2.5rem] rounded-b-md border-solid border-green-600 border-2 pl-1' placeholder='Profesion' disabled value={data.profession} />
				</div>
				<div className='flex flex-col justify-center items-center'>
					<label className='text-[1.2rem] w-[80%]'>Tipo de contratación</label>
					<input type="text" className='w-[80%] h-[2.5rem] rounded-b-md border-solid border-green-600 border-2 pl-1' placeholder='Tipo de contratación' disabled value={employmentRelationship} />
				</div>
				<div className='flex flex-col justify-center items-center'>
					<label className='text-[1.2rem] w-[80%]'>Regional</label>
					<input type="text" className='w-[80%] h-[2.5rem] rounded-b-md border-solid border-green-600 border-2 pl-1' placeholder='Regional' disabled value={data.regional}/>
				</div>
				<div className='flex flex-col justify-center items-center'>
					<label className='text-[1.2rem] w-[80%]'>Centro de formación</label>
					<input type="text" className='w-[80%] h-[2.5rem] rounded-b-md border-solid border-green-600 border-2 pl-1' placeholder='Centro de formación' disabled value={data.trainingCenter} />
				</div>
			</div>
		</div>
)
}
