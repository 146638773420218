import axios from 'axios';
import React,{useContext, useState} from 'react'
import { url } from '../../ApiRoutes';
import { userContext } from '../../UserProvider/UserProvider'
import { styles } from '../../styles';
export const UpdateRooms = ({updateData}) => {
  
  const {user} = useContext(userContext)

  const [number, setNumber]=useState(updateData.number)
  const [type, setType]=useState(updateData.type)
  const [roomName, setroomName]=useState(updateData.roomName)
  const [description, setDescription]=useState(updateData.description)
  const [multiRooms, setMultirooms]=useState(updateData.multiRooms)


  const config = {
    headers: { Authorization: `Bearer ${user.data.token}` }
  };

  const bodyParameters = {
    number:number,
    type:type, 
    roomName:roomName, 
    description:description,
    multiRooms:multiRooms
  };
  const updateRoomsData = (()=>{
    axios.put(url.UpdateRooms+updateData.number, bodyParameters, config)
    .then(response => {
      console.log(response);
      window.location.reload()
    })
    .catch(ex=>{
      console.log(ex.response.data)
    })
  })
  
  return (
    <div className={`w-full h-[60rem] flex flex-col gap-[5rem] items-center`}>
      <div className={`${styles.titleContainer} border-b-2`}>
        <h1 className={`${styles.title}`}> Actualizar Ambientes</h1>
      </div>
      <div className={`w-[50%] h-[50%] flex justify-center sm:gap-[3rem] items-center sm:flex-col flex-col`} >
        <div className='h-full flex flex-col justify-around'>
          <div className='flex flex-col'>
            <label className='text-[1.2rem]'>Numero de ambiente</label>
            <input type="number" className={`${styles.input}`} placeholder={updateData.number} onChange={(e)=>{setNumber(e.target.value)}} />
          </div>
          <div className='flex flex-col'>
            <label className='text-[1.2rem]'>Numero de ambiente</label>
            <input type="text" className={`${styles.input}`}  placeholder={updateData.type} onChange={(e)=>{setType(e.target.value)}}/>
          </div>
          <div className='flex flex-col'>
            <label className='text-[1.2rem]'>Numero de ambiente</label>
            <input type="text" className={`${styles.input}`}  placeholder={updateData.roomName} onChange={(e)=>{setroomName(e.target.value)}}/>
          </div>
          <div className='flex flex-col'>
            <label className='text-[1.2rem]'>Numero de ambiente</label>
            <input type="text" className={`${styles.input}`}  placeholder={updateData.description} onChange={(e)=>{setDescription(e.target.value)}} />
          </div>
          <div className='flex flex-col'>
            <label className='text-[1.2rem]'>Numero de ambiente</label>
            <select name="employmentRelationship" className={`${styles.input}`}  placeholder={updateData.multiRooms} onChange={(e)=>{setMultirooms(e.target.value)}} >
              <option selected disabled>Cuentadante Múltiple</option>
              <option>Si</option>
              <option>No</option>
            </select>
          </div>
        </div>
      </div>
      <div className='text-center'>
        <button className={`${styles.formButton}`} onClick={updateRoomsData}>Actualizar</button>
      </div>
    </div>
  )
}
