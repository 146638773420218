import React from 'react'
import { styles } from '../../styles'

export const GoodInformation = ({goodInformation}) => {
  return (
    <div className={`${styles.goodInformation}`}>
    <p className={`w-[4rem] text-center`}>{goodInformation.labels}</p>
    <p className={`w-[7rem] text-center truncate`}>{goodInformation.element}</p>
    <p className={`w-[7rem] text-center truncate`}>{goodInformation.classDescription}</p>
    <p className={`w-[7rem] text-center truncate`}>{goodInformation.atributtes}</p>
    <p className={`w-[5rem] text-center`}>{goodInformation.purchasingValue}</p>
    <p className={`w-[5rem] text-center`}>{goodInformation.currentLocation}</p>
  </div>
  )
}