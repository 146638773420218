import './RememberPassword.css';
import React, { useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { url } from '../../ApiRoutes'
import greenlogo from '../../assets/greenlogo.jpg'

export const RememberPassword = () => {
  const [email, setEmail]=useState('')
  const [token, setToken] = useState('');

  const setEnventToEmail=(event)=>{
    setEmail(event.target.value)
  } 

  const verifyEmail = () =>{
    axios.post(url.ConfirmEmail+email)
    .then(response => {
      if(response.data!== false){
        setToken(`http://localhost:3000/RecoverPassword/?token=${response.data.data.token}`)
        sendEmail()
      }else{
        Swal.fire({title: 'Error!',text: 'El correo ingresado no existe',icon: 'error',confirmButtonText: 'Ok'})
      }
    }) 
  }

  const sendEmail =() =>{
    axios.post(url.SendEmail+email,{
      "token":token
    })
    .then(response=>{
      Swal.fire({title: 'Enviado!',text: 'Correo enviado',icon: 'success',confirmButtonText: 'Ok'
      })
      setEmail('')
    })
  }

  
  const keyUp=(event)=>{
    if (event.keyCode===13) {
      verifyEmail()
    }
  }

return (
  <div className='bg-gradient-to-r from-[#2F7A25] w-full h-screen to-[rgb(23,199,0)] shadow flex justify-center items-center flex-col '>
      <div className='bg-white  w-[500px] h-[500px]  rounded-md  flex justify-between items-center flex-col container_remember_app '>
          <div className='mt-10  w-[10rem] h-[10rem] container_img_remember '>
              <img src={greenlogo} alt="logo_inventario"  className='rounded  '/>
          </div>
          <div>
              <h1 className='text-[2rem] title_contaseña'>Recuperar Contraseña</h1>
          </div>
          <div>
              <h2 className='text-[14px] text-center p-[20px_10px] sub_title'>Ingrese el correo con que este  registrado</h2>
          <input type="text" placeholder='Ingrese su correo' className='w-full rounded-b-md border-solid border-green-600 border-2  p-1 input_password' onChange={e => setEnventToEmail(e)} value={email}  onKeyUp={(e)=>keyUp(e)}/>
          </div>
          <div className='flex justify-between gap-[2rem] p-2' >
              <Link to='/' className='bg-red-500 p-[5px_30px] rounded text-white text-[14px] btn_volver'><button>Volver</button></Link>
              <button className='bg-green-600 p-[5px_30px] rounded text-white text-[14px] btn_volver' onClick={event => (verifyEmail(event))} >Enviar</button>
          </div>
      </div>
  </div>
)
}
