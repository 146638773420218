import axios from 'axios';
import Swal from 'sweetalert2';
import { styles } from '../../styles';
import React, { useContext, useState } from 'react';
import { userContext } from '../../UserProvider/UserProvider';
import { url } from '../../ApiRoutes';
import { ButtonUploadFile } from '../../UI/ButtonUploadFile/ButtonUploadFile';

export const RegisterUser = () => {

  const [document, setDocument] = useState('');
  const [names, setNames] = useState('');
  const [lastNames, setLastNames] = useState('');
  const [homeAddress, setHomeAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [cellPhoneNumber, setCellPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [profession, setProfession] = useState('');
  const [trainingCenter, setTrainingCenter] = useState('');
  const [regional, setRegional] = useState('');
  const [employmentRelationship, setEmploymentRelationship] = useState('default');
  const [role, setRole] = useState('default');
  const {user} = useContext(userContext)

  const formData = new FormData()
  const [file, setFile] = useState()

  const configMultipart = {
    headers: {
      'content-type': 'multipart/form-data',
    }}

  const captureExcel = (e) => {
    setFile(e.target.files[0])
  }

  const insertfile = () => {
    if (file !== undefined){
      formData.append('excel', file);
      formData.append('fileName', file.name);
      axios.post(url.InserUsersByXlsx, formData, configMultipart)
      .then(response => {
        console.log(response)
        window.location.reload()
      })
      .catch(ex => {
        console.log(ex.response)
      })
    } else {
      Swal.fire({title: '¡Error!', text: 'No se selecciono archivo', icon:'error', confirmButtonText: 'Ok'})
    }
  }

  const config = {
    headers: { Authorization: `Bearer ${user.data.token}` }
  };

  const registerUser = () => {
    if(employmentRelationship === 'default' || role === 'default'){
      Swal.fire({title: '¡Error!', text: 'Debe de seleccionar un tipo de vinculación o rol', icon:'error', confirmButtonText: 'Ok'})
    } else if (document !== '' || names !== '' || lastNames !== '' || homeAddress !== '' || phoneNumber !== '' || cellPhoneNumber !== '' || email !== '' || profession !== '' || trainingCenter !== '' || regional !== '') {
      axios.post(url.RegisterUser,{
        document: document,
        names: names, 
        lastNames: lastNames, 
        homeAddress: homeAddress, 
        phoneNumber: phoneNumber, 
        cellPhoneNumber: cellPhoneNumber, 
        email: email,
        profession: profession, 
        trainingCenter : trainingCenter, 
        regional: regional, 
        employmentRelationshipId : employmentRelationship, 
        roleId : role
      },config,)
      .then(response => {
        setDocument('');
        setNames('');
        setLastNames('');
        setHomeAddress('');
        setPhoneNumber('');
        setCellPhoneNumber('');
        setEmail('');
        setProfession('');
        setTrainingCenter('');
        setRegional('');
        setEmploymentRelationship('default');
        setRole('default');
        Swal.fire({title: 'Registro exitoso', text:`El usuario se creo correctamente`, icon: 'success', confirmButtonText: 'Ok'})
      })
      .catch(ex=>{
        console.log(ex.response);
      })
    }
    else{
      Swal.fire({title: '¡Error!', text: 'Campos vacíos', icon:'error', confirmButtonText: 'Ok'})
    }
  }

  return (
    <>
      <div className={`w-full h-screen flex justify-between flex-col  items-center`}>
        <div className={`${styles.titleContainer}`}>
          <h1 className={`${styles.title}`}>Registro usuarios</h1>
        </div>
        <div className={`${styles.formContainer} sm:flex-row flex-col`} >
          <div className='flex flex-col h-[25rem] justify-evenly'>
            <input type="number" className={`${styles.input} appearance-none`} placeholder="Documento" value={document} onChange={(e)=>{setDocument(e.target.value)}}/>
            <input type="text" className={`${styles.input}`} placeholder="Apellidos" value={lastNames} onChange={(e)=>{setLastNames(e.target.value)}}/>
            <input type="number" className={`${styles.input}`} placeholder="Celular" value={cellPhoneNumber} onChange={(e)=>{setCellPhoneNumber(e.target.value)}}/>
            <input type="text" className={`${styles.input}`} placeholder="Profesión" value={profession} onChange={(e)=>{setProfession(e.target.value)}}/>
            <input type="email" className={`${styles.input}`} placeholder="Correo electrónico" value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
            <input type="text" className={`${styles.input}`} placeholder="Centro de formación" value={trainingCenter}  onChange={(e)=>{setTrainingCenter(e.target.value)}}/>
          </div>
          <div className='flex flex-col h-[25rem] justify-evenly'>
            <input type="text" className={`${styles.input}`} placeholder="Nombres" value={names} onChange={(e)=>{setNames(e.target.value)}}/>
            <input type="text" className={`${styles.input}`} placeholder="Dirección" value={homeAddress} onChange={(e)=>{setHomeAddress(e.target.value)}}/>
            <input type="number" className={`${styles.input}`} placeholder="Teléfono" value={phoneNumber} onChange={(e)=>{setPhoneNumber(e.target.value)}}/>
            <select name="employmentRelationship" className={`${styles.input}`} value={employmentRelationship} onChange={(e)=>{setEmploymentRelationship(e.target.value)}}>
              <option value='default' disabled="disabled">Tipo de vinculación</option>
              <option value='EM01'>Administrativo</option>
              <option value='EM02'>Instructor Contrato</option>
              <option value='EM03'>Instructor Planta</option>
            </select>
            <select name="role" className={`${styles.input}`} value={role} onChange={(e)=>{setRole(e.target.value)}}>
              <option value='default' disabled="disabled">Rol</option>
              <option value='R01'>Administrador</option>
              <option value='R02'>Instructor</option>
              <option value='R03'>Conserje</option>
            </select>
            <input type="text" className={`${styles.input}`} placeholder="Regional" value={regional} onChange={(e)=>{setRegional(e.target.value)}}/>
          </div>
        </div>
        <div className={`${styles.formButtonContainer}`}>
          <button className={`${styles.formButton}`} onClick={registerUser}>Registrar</button>
        </div>
          <ButtonUploadFile captureExcel={captureExcel} />
            <div className={`${styles.formButtonContainer}`}>
              <button className={`${styles.formButton}`} onClick={insertfile}>Registrar archivo</button>
            </div>
        </div>
    </>
  )
}