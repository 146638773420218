/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React , { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { styles } from '../../Components/styles';
import { url } from '../../Components/ApiRoutes';
import { Header } from '../../Components/Layout/Header/Header';
import { Dashboard } from '../../Components/Layout/Dashboard/Dashboard';
import { userContext } from '../../Components/UserProvider/UserProvider';
import { HeaderDashboard } from '../../Components/UI/HeaderDashboard/HeaderDashboard';
import { ChangeInformationDashboard } from '../../Components/Layout/ChangeInformationDashboard/ChangeInforationDashboard';
import { FooterHome } from '../../Components/UI/FooterHome/FooterHome';
import { RoomsGoods } from '../../Components/Layout/RoomsGoods/RoomsGoods';
import { RoomsInformationConcierge } from '../../Components/UI/RoomsInformationConcierge/RoomsInformationConcierge';

export const RoomsInstructor = () => {

  const {user} = useContext(userContext);
  const [data,setData] = useState([]);
  const [optionData,setOptionData]=useState('1')
  const [stateUser, setStateUser] = useState('active');
  const [stateAdministrator, setStateAdministrator] = useState('');  
  const [updateData, setUpdateData]=useState([]);
  const [multiRoomsData, setMultiRoomsData] = useState([]);

  const config = {
      headers: { Authorization: `Bearer ${user.data.token}` }
  };

  const getAllRooms = () => {
      axios.get(url.AllRooms,config)
      .then(response => {
          setData(response.data.rooms)
      })
      .catch(ex=>{
      console.log('ERROR'+ex);
      })
  };

  const getMultiRooms = () => {
    axios.get(url.MultiRooms, config)
    .then(response => {
      setMultiRoomsData(response.data.multiRoom)
    })
    .catch(ex=>{
      console.log(ex.response.data.error);
    })
  }

  const setNumberOption = ((event)=>{
      setOptionData(event.target.value)
    })

    const setDataToUpdata = ((event,index)=> {
      setUpdateData(data[index])
      setOptionData('3')
    })

  useEffect(()=>{
    getAllRooms()
    getMultiRooms()
  },[''])

  const headerInformation = {
    names: 'Gestionar',
    lastNames: 'Ambientes',
      links: [
        {
          url:'/',
          name: 'Inicio'
        },
        {
          url: '',
          name: 'Lista de ambientes',
        },
        {
          url: '',
          name: 'Consultar ambientes',
        },
      ]
  }

  const sizeColumn = {
    column1 : "9rem",
    column2 : "7rem",
    column3 : "9rem",
    column4 : "8rem",
    column5 : "9rem",
    column6 : "2rem",
  }

  const buttonInformation = {
    buttonWidth1 : "7rem",
    buttonWidth2 : "9rem",
    buttonText1 : "Ambientes",
    buttonText2 : "Multiambientes",
    stateUser : stateUser,
    setStateUser : setStateUser,
    stateAdministrator : stateAdministrator,
    setStateAdministrator : setStateAdministrator
  }

  const headerDashboardInformation = {
      columnText1 : "Número",
      columnText2 : "Tipo",
      columnText3 : "Nombre",
      columnText4 : "Descripción",
      columnText5 : "Ambiente múltiple",
      columnText6 : "Cantidad bienes ",
      columnWidth1 : sizeColumn.column1,
      columnWidth2 : sizeColumn.column2,
      columnWidth3 : sizeColumn.column3,
      columnWidth4 : sizeColumn.column4,
      columnWidth5 : sizeColumn.column5,
      columnWidth6 : sizeColumn.column6,
  }
  
  return (
    <>
    <div>
        <div className='sm:w-[100%]'>
          <Header data={headerInformation} setNumber={setNumberOption} />
        </div>
        <div className='sm:w-[100%] w-[100%] flex justify-center items-center flex-col'>
          <div className={`${styles.mainContainer}`}>
            {optionData === '1' && 
            <>
              <ChangeInformationDashboard data={stateUser === 'active' ? data : multiRoomsData} buttonInformation={buttonInformation}/>
              <HeaderDashboard headerDashboardInformation={headerDashboardInformation}/>
              {
                stateAdministrator === 'active' &&
                <Dashboard dashboardInformation={
                  multiRoomsData?.map((item, index)=>
                    <RoomsInformationConcierge roomsInformation={item} key={item.document} roomslength={data[index].goods} updateData={setDataToUpdata} index={index}/>
                )}/>                
              }
              {
                stateUser === 'active' && 
                <Dashboard dashboardInformation={
                  data?.map((item, index)=>
                    <RoomsInformationConcierge roomsInformation={item} key={item.document} roomslength={data[index].goods} updateData={setDataToUpdata} index={index}/>
                )}/>
              }
            </>
            }
            {optionData === '2' &&
                <RoomsGoods/>
            }
              </div>
            <div className='w-full'>
              <FooterHome/>
            </div>
          </div>
    </div>
    </>
  )
}
