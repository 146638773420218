import React, { useState } from 'react'

export const HelpCard = ({helpText}) => {

  const [active,setActive] = useState(false)

  const activeModal = () => {
    setActive(!active)
  }

  return (
    <div onClick={activeModal}>
      <div className='w-6 h-6 rounded-full bg-[#508ca4] flex justify-center items-center cursor-pointer'>
        <p className='text-white'>?</p>
      </div>
      {active && 
        <div className='absolute right-8 mt-2 w-[16rem] h-[100%] flex  flex-col items-center bg-slate-200 rounded-xl shadow-xl z-10'>
          <div className='h-[30%] flex flex-col justify-center items-center gap-10'>
            <h1 className='text-[1.3rem]'>¿Necesitas ayuda?</h1>
            <h1 className='text-[1.1rem]'>Como usar Inventario</h1>
          </div>
          <div className='w-[90%] flex items-center'>
            <p>
              En esta página podrás acceder a Ambientes, Bienes, Inventario, Reportes y Novedades, además se podrá consultar los bienes de un cuentadante ingresando la cédula en el campo Busca por número de cedula y ver la información de un bien ingresando la placa en el campo busca por número de placa
            </p>
          </div>
        </div>
      }
    </div>
  )
}
