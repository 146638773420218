import React from 'react'
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserImage } from '../UserImage/UserImage'


export const HeaderUser = ({userActive,userSession,img, userName}) => {

  const [active, setActive]=useState(false);
  const navigate = useNavigate()


  const closeSesion = () => {
    if(userActive === 'user-active' & userSession === 1 ){
      localStorage.removeItem('userInfo')
      localStorage.setItem('userInfo',null)
      navigate('/')
      window.location.reload()
    }
  }

  return (
    <div>
        <button className='sm:h-14 sm:w-14 h-14 w-14 rounded-full overflow-hidden'  onClick={(e)=>setActive(!active)} >
          {img ? 
            <img className='h-full w-full object-cover' src={img} alt="Avatar usuario" />
            :
            <UserImage/>
            }
        </button>
        {active && (
            <div className='absolute right-0  mt-2 w-48 bg-slate-200 rounded-lg shadow-xl'>
              <Link to='/Profile'><button className='w-full h-[2.5rem] text-left pl-3 text-gray-500 hover:bg-[#c3c3c3] hover:text-white'>Mi perfil</button></Link>
              <button className='w-full h-[2.5rem] text-left pl-3 text-gray-500 hover:bg-[#c3c3c3] hover:text-white' onClick={(e)=>closeSesion()}>Cerrar sesión</button>
            </div>
        )}
    </div>
  )
}
