import axios from 'axios';
import React, { useContext, useState } from 'react';
import Swal from 'sweetalert2';
import { url } from '../../ApiRoutes';
import { styles } from '../../styles';
import { ButtonUploadFile } from '../../UI/ButtonUploadFile/ButtonUploadFile';
import { userContext } from '../../UserProvider/UserProvider';

export const RegisterGood = () => {

  const [label, setLabel] = useState('');
  const [element, setElement] = useState('');
  const [classDescription, setClassDescription] = useState('');
  const [atributtes, setAtributtes] = useState('');
  const [purchasingValue, setPurchasingValue] = useState('');
  const [currentLocation, setCurrentLocation] = useState('');
  const [mode, setMode] = useState('M01');
  const [number, setNumber] = useState('');
  const [goodState, setGoodState] = useState('GS01');
  const [document, setDocument] = useState('');
  const {user} = useContext(userContext);

  const formData = new FormData()
  const [file, setFile] = useState()

  
  const configMultipart = {
    headers: {
      'content-type': 'multipart/form-data',
    }}

  const config = {
    headers: { Authorization: `Bearer ${user.data.token}` }
  };

  const captureExcel = (e) => {
    setFile(e.target.files[0])
  }

  const insertfile = () => {
    if (file !== undefined){
      formData.append('excel', file);
      formData.append('fileName', file.name);
      axios.post(url.InserGoodsByXlsx, formData, configMultipart)
      .then(response => {
        window.location.reload()
      })
      .catch(ex => {
        console.log(ex.response)
      })
    } else {
      Swal.fire({title: 'Error!', text: 'No se selecciono archivo', icon:'error', confirmButtonText: 'Ok'})
    }
  }

  const registerGood = () => {
    if (label !== '' || element !== '' || classDescription !== '' || atributtes !== '' || purchasingValue !== '' || currentLocation !== '' || number !== '' || document !== '' ) {
      axios.post(url.RegisterGood,{
        labels: label,
        element: element,
        classDescription: classDescription,
        atributtes: atributtes,
        purchasingValue: purchasingValue,
        currentLocation: currentLocation,
        modeId: mode,
        number: number,
        goodStateId: goodState,
        document: document
      }, config)
      .then(response => {
        console.log(response);
        setLabel('');
        setElement('');
        setClassDescription('');
        setAtributtes('');
        setPurchasingValue('');
        setCurrentLocation('');
        setMode('M01');
        setNumber('');
        setGoodState('GS01');
        setDocument('');
      })
      .catch(ex => {
        console.log(ex.response.data.errors);
      })
    }else{
      Swal.fire({title: 'Error!', text: 'Campos vacios', icon:'error', confirmButtonText: 'Ok'})
    }
  }

  return (
    <>
      <div className={`w-full h-screen flex justify-between flex-col  items-center`}>
        <div className={`${styles.titleContainer} border-b-2`}>
          <h1 className={`${styles.title}`}>Registro bienes</h1>
        </div>
        <div className={`${styles.formContainer} sm:flex-row flex-col`} >
          <div className='flex flex-col h-[20rem] justify-evenly'>
            <input type="text" className={`${styles.input}`} placeholder="Placa" value={label} onChange={(e)=>{setLabel(e.target.value)}}/>
            <input type="text" className={`${styles.input}`} placeholder="Elemento" value={element} onChange={(e)=>{setElement(e.target.value)}}/>
            <input type="text" className={`${styles.input}`} placeholder="Descripción" value={classDescription} onChange={(e)=>{setClassDescription(e.target.value)}}/>
            <input type="text" className={`${styles.input}`} placeholder="Atributos" value={atributtes} onChange={(e)=>{setAtributtes(e.target.value)}}/>
            <input type="text" className={`${styles.input}`} placeholder="Ubicación actual" value={currentLocation} onChange={(e)=>{setCurrentLocation(e.target.value)}}/>
          </div>
          <div className='flex flex-col h-[20rem] justify-evenly'>
            <input type="text" className={`${styles.input}`} placeholder="Valor" value={purchasingValue} onChange={(e)=>{setPurchasingValue(e.target.value)}}/>
            <input type="text" className={`${styles.input}`} placeholder="Número ambiente" value={number}  onChange={(e)=>{setNumber(e.target.value)}}/>
            <input type="text" className={`${styles.input}`} placeholder="Documento" value={document} onChange={(e)=>{setDocument(e.target.value)}}/>
            <select name="mode" className={`${styles.input}`} value={mode} onChange={(e)=>{setMode(e.target.value)}}>
              <option value='M01'>Activo</option>
              <option value='M02'>Inactivo</option>
            </select>
            <select name="goodState" className={`${styles.input}`} value={goodState} onChange={(e)=>{setGoodState(e.target.value)}}>
              <option value='GS01'>Bueno</option>
              <option value='GS02'>Regular</option>
              <option value='GS03'>Malo</option>
            </select>
          </div>
        </div>
        <div className={`${styles.formButtonContainer}`}>
          <button className={`${styles.formButton}`} onClick={registerGood}>Registrar</button>
        </div>
        <ButtonUploadFile captureExcel={captureExcel}/>
        <div className={`${styles.formButtonContainer}`}>
          <button className={`${styles.formButton}`} onClick={insertfile}>Registrar archivo</button>
        </div>
      </div>
    </>
  )
}
