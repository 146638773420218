import './FooterHome.css'
import React from 'react'
import LogotipoInventario from '../../assets/LogotipoInventario.png'
import LogoSena from '../../assets/logoSena.png'
import { NavLink } from 'react-router-dom'

export const FooterHome = () => {
  return (
    <section className='w-full sm:h-[14.5rem] h-[28rem] flex justify-around  items-center flex-col bg-gradient-to-r from-[#17C700]  to-[#2F7A25] text-white'>
      <div>
        <img src={LogotipoInventario} alt="logotipo" className='w-[14rem] h-3.5rem]' />
      </div>
      <div className='w-full flex justify-center items-center gap-3'>
        <NavLink className='text-[1.3rem]'>Titulo</NavLink>
        <NavLink className='text-[1.3rem]' >Titulo</NavLink>
        <NavLink className='text-[1.3rem]' >Titulo</NavLink>
        <NavLink className='text-[1.3rem]' >Titulo</NavLink>
      </div>    
      <div className='w-[90%] h-[4rem] flex justify-between items-center'>   
        <img src={LogoSena} alt="" className='w-[6rem] h-[5rem]' />
        <p>&copy;Todos los derechos resevados || 2023</p>
      </div>
    </section>
  )
}
