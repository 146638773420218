import './CardHome.css'
import React from "react";

export const CardHome = ({ number, title, icon }) => {
  return (
    <div className="h-[8rem] w-[14rem] shadow-lg flex justify-evenly items-center gap-[1rem]  rounded-md ">
      <div className={`bg-[#508ca4] rounded-[50%] h-[3.7rem] w-[3.7rem] flex justify-center items-center`}>
        {icon}
      </div>
      <div className="flex flex-col  text-center container-card-text">
        <p className="text-[1.3rem]">{title}</p>
        <p className="text-[1.3rem] floating">{number}</p>
      </div>
    </div>
  );
};
