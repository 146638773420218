import React from 'react';
import {PencilEdit} from "@vectopus/atlas-icons-react";


export const GoodAssociatingInventory = ({goodInformation, updateData, index}) => {
  return (
    <div className={`h-[2.5rem] bg-[#ececec] rounded w-[97%] flex items-center justify-around gap-[1rem]`}>
      <p className={`w-[3rem] text-center`}>{goodInformation.labels}</p>
      <p className={`w-[6rem] text-center truncate`} title={goodInformation.element}>{goodInformation.element}</p>
      <p className={`w-[7rem] text-center truncate`} title={goodInformation.classDescription}>{goodInformation.classDescription}</p>
      <p className={`w-[5rem] text-center truncate`} title={goodInformation.atributtes}>{goodInformation.atributtes}</p>
      <p className={`w-[4rem] text-center`}>{goodInformation.purchasingValue}</p>
      <p className={`w-[7rem] text-center`}>{goodInformation.currentLocation}</p>
      <div className="w-[4rem] flex items-center">
        <button className={`flex justify-center items-center`} onClick={e => updateData(e,index)}><PencilEdit size={20}/></button>
      </div>
    </div>
  )
}
