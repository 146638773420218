export const styles = {
  boxWidth: "xl:max-w-[1280px] w-full",
  button:"rounded hover:text-white sm:w-[7rem] ss:w-[6rem] lg:w-[9.5rem] w-[5rem] sm:text-[1rem] text-[0.8rem] text-center bg-[#17C700]",
  heading2: "font-poppins font-semibold xs:text-[48px] text-[40px] text-white xs:leading-[76.8px] leading-[66.8px] w-full",
  paragraph: "font-poppins font-normal text-dimWhite text-[18px] leading-[30.8px]",

  flexCenter: "flex justify-center items-center",
  flexStart: "flex justify-center items-start",

  paddingX: "sm:px-16 px-6",
  paddingY: "sm:py-16 py-6",
  padding: "sm:px-16 px-6 sm:py-12 py-4",

  marginX: "sm:mx-16 mx-6",
  marginY: "sm:my-16 my-6",

  principalContainer: "w-full h-[50rem] flex flex-col justify-center items-center",
  principalFormContainer: "w-full h-[65rem] flex flex-col gap-[1rem] items-center",
  titleContainer: "sm:w-[70%] w-[100%] h-[10rem] flex justify-around items-center",
  formContainer: "w-[80%] h-[70%] flex justify-center sm:gap-[3rem] items-center",
  formContainer2:"w-[80%] h-[60%] flex flex-col item-center justify-center gap-[2rem]",
  title: "text-[2.5rem]",
  line: "w-24",
  formButton:"rounded w-40 h-10 text-center bg-[#17C700] text-lg hover:font-semibold hover:text-black hover:bg-[#ccc]",
  formButtonContainer:"flex w-[70% h-[12rem] justify-around items-center",
  input: "w-[20rem] h-[2.5rem] rounded-b-md border-solid border-green-600 border-2 pl-1 outline-none",
  fileInput: "block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-[#17C700] file:text-black hover:file:bg-[#ccc]",

  headerDashboard: " h-[3rem] flex w-[80%] bg-[#0292b7] justify-evenly items-center rounded",
  mainContainer: "w-full h-[60rem] flex flex-col justify-evenly items-center",
  dashboardContainer: "w-[58rem] h-[30rem] bg-[#ccc] rounded flex flex-col gap-[0.5rem] justify-start items-center p-[1rem] overflow-auto",
  userInformation: "h-[3rem] bg-[#ececec] rounded w-[56rem] flex items-center gap-[2.2rem]",
  goodInformation: "h-[2.5rem] bg-[#ececec] rounded w-[97%] flex items-center justify-evenly gap-[1rem]" ,
  roomsInformation:"h-[3rem] bg-[#ececec] rounded w-[56rem] flex gap-[2rem] items-center "
}
