import React from 'react';
import { styles } from '../../styles';
import {MagnifyingGlass} from "@vectopus/atlas-icons-react";


export const InputReport = ({reportName, reportPlaceholcer, setState, generateReport, value, ketUp}) => {

  const keyUp=(event)=>{
    if (event.keyCode===13) {
      generateReport()
    }
  }

  return (
    <div className='sm:w-[40rem] w-[90%] flex flex-col justify-center gap-4 items-center'>
      <div>
        <h2 className='text-[1.5rem]'>{reportName}</h2>
      </div>
      <div className='w-[100%] flex justify-between items-center'>
        <div className='relative'>
          <input className={`${styles.input} pl-[2.5rem]`} placeholder={reportPlaceholcer} onChange={(e)=>setState(e)} value={value}  onKeyUp={(e)=>keyUp(e)}/>
          <MagnifyingGlass className='absolute left-[0.75rem] top-[50%] translate-y-[-50%]' size={18} color={'#515151'}/>
        </div>
        <button className={`${styles.formButton}`} onClick={generateReport}>Generar</button>
      </div>
    </div>
  )
}
