import React from 'react'
import { LoginForm } from '../../Layout/LoginForm/LoginForm'
import LogoInventario from '../../assets/LogoInventario.png'

export const Login = () => {
return (
  <div className='h-screen w-screen flex justify-center items-center bg-gradient-to-r from-[#2F7A25] to-[#17c700] '>
    <div className='w-[60%] flex rounded-xl justify-center items-center'>
      <LoginForm/>
      <div className='sm:flex hidden bg-[#E6E6E6] md:w-[35rem] w-[100%] h-[30rem] justify-center items-center rounded-r-md '>
        <img className='h-[10rem] w-[10rem] ' src={LogoInventario} alt="Logo del InvnetarioSENA" />
      </div>
    </div>
  </div>
)
}
