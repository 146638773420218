import React, { useContext } from 'react'
import { decodeToken } from 'react-jwt'
import { userContext } from '../../UserProvider/UserProvider'


export const UserImage = () => {
  
  const {user} = useContext(userContext)
  const tokenData = decodeToken(user.data.token)

  return (
    <div className='h-full w-full bg-[#D2F7C8] flex justify-center items-center'>
        <h1 className='font-semibold text-[1.4rem]'>{tokenData.names.toUpperCase().charAt(0)}</h1>
    </div>
  )
}
