/* eslint-disable no-unused-vars */
import axios from 'axios'
import React, { useState, useContext } from 'react'
import Swal from 'sweetalert2'
import { url } from '../../Components/ApiRoutes'
import { Header } from '../../Components/Layout/Header/Header'
import { styles } from '../../Components/styles'
import { FooterHome } from '../../Components/UI/FooterHome/FooterHome'
import { InputReport } from '../../Components/UI/InputReport/InputReport'
import { userContext } from '../../Components/UserProvider/UserProvider'


export const Reports = () => {

  const [optionData,setOptionData] = useState('0')
  const [userDocument,setUserDocument] = useState('')
  const [roomNumber, setRoomNumber] = useState('')
  const [roomNumberUpdate, setRoomNumberUpdate] = useState('')
  const {user} = useContext(userContext);

  const setNumberOption = ((event)=>{
    setOptionData(event.target.value)
  })

  const setDocument = ((event) => {
    setUserDocument(event.target.value)
  })
  
  const setRoom = ((event) => {
    setRoomNumber(event.target.value)
  })

  const setRoomUpdate = ((event) => {
    setRoomNumberUpdate(event.target.value)
  })


  const config = {
    headers: { Authorization: `Bearer ${user.data.token} `, 'Content-Type': 'multipart/form-data' }
  };

  const headerInformation = {
    names: 'Gestionar',
    lastNames: 'Reportes',
		links: [
      {
        url:'/',
        name: 'Inicio'
      },
			{
				url: '',
				name: 'Generar reporte',
			}
		]
  }

  const generateReportGoods = () => {
    if (userDocument !== '') {
      axios.get(url.GenerateReportGoods+userDocument, config )
      .then(response => {
        window.open(url.GenerateReportGoods+userDocument)
        setUserDocument('')
      })
      .catch(ex => {
        console.log(ex);
        if (ex.response.data.error === 'USER_NOT_EXIST') {
          Swal.fire({title: '¡Error!',text: 'Este usuario no esta registrado',icon: 'error',confirmButtonText: 'Ok'
          })
        }
      })
    }
    else{
      Swal.fire({title: '¡Error!',text: 'Campos vacíos',icon: 'error',confirmButtonText: 'Ok'
      })
    }
  }

  const generateReportRooms = () => {
    if (roomNumber !== '') {
      axios.get(url.GenerateReportRooms+roomNumber, config )
      .then(response => {
        window.open(url.GenerateReportRooms+roomNumber)
        setRoomNumber('')
      })
      .catch(ex => {
        if (ex.response.data.error === 'ROOM_NOT_EXIST') {
          Swal.fire({title: '¡Error!',text: 'Este ambiente no esta registrado',icon: 'error',confirmButtonText: 'Ok'
          })
        }
      })
    }
    else{
      Swal.fire({title: '¡Error!',text: 'Campos vacíos',icon: 'error',confirmButtonText: 'Ok'
      })
    }
  }

  const generateReportRoomsUpdates = () => {
    if (roomNumberUpdate !== '') {
      axios.get(url.GenerateReportRoomsUpdates+roomNumberUpdate, config )
      .then(response => {
        window.open(url.GenerateReportRoomsUpdates+roomNumberUpdate)
        setRoomNumberUpdate('')
      })
      .catch(ex => {
        if (ex.response.data.error === 'ROOM_NOT_EXIST') {
          Swal.fire({title: '¡Error!',text: 'Este ambiente no esta registrado',icon: 'error',confirmButtonText: 'Ok'
          })
        }
      })
    }
    else{
      Swal.fire({title: '¡Error!',text: 'Campos vacíos',icon: 'error',confirmButtonText: 'Ok'
      })
    }
  }


  return (
    <div>
      <div className='sm:w-[100%]'> 
        <Header data={headerInformation} setNumber={setNumberOption} />
      </div>
      <div className='sm:w-[100%] w-full flex justify-center items-center flex-col'>
        <div className='w-[100%] h-[100vh] flex  items-center flex-col'>
          <div className={`${styles.titleContainer} border-b-2`}>
            <h1 className='text-[2.3rem]'>Gestionar reportes</h1>
          </div>
          <div className='w-[90%] h-[70%] flex justify-evenly items-center flex-col'>
            <InputReport reportName='Generar reporte de bienes por Cuentadante' reportPlaceholcer='Numero de documento' setState={setDocument} generateReport={generateReportGoods} value={userDocument}/>
            <InputReport reportName='Generar reporte de bienes por Ambiente' reportPlaceholcer='Número de ambiente'    setState={setRoom} generateReport={generateReportRooms} value={roomNumber} />
            <InputReport reportName='Generar reporte por prestamos de Ambiente' reportPlaceholcer='Número de ambiente' setState={setRoom} />
            <InputReport reportName='Generar reporte por novedades de Ambeinte' reportPlaceholcer='Núnmero de ambiente' setState={setRoomUpdate} generateReport={generateReportRoomsUpdates} value={roomNumberUpdate}/>
          </div>
        </div>
        <div className='w-full'>
          <FooterHome/>
        </div>
      </div>
    </div>
  )
}
