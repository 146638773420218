import './App.css';
import {Routes, Route} from 'react-router-dom'
import { Home } from './Components/Pages/Home/Home';
import { Login } from './Components/Pages/Login/Login';
import { UserProvider } from './Components/UserProvider/UserProvider';
import { RememberPassword } from './Components/Layout/RememberPassword/RememberPassword.jsx';
import { RecoverPassword } from './Components/Layout/RecoverPassword/RecoverPassword';
import { FooterHome } from './Components/UI/FooterHome/FooterHome';
import { HeaderUser } from './Components/UI/HeaderUser/HeaderUser';
import { Profile } from './Components/Pages/Profile/Profile';
import { RegisterUser } from './Components/Layout/RegisterUser/RegisterUser';
import { UserProfile } from './Components/Pages/UserProfile/UserProfile';
import { Users } from './Modules/Admin/Users';
import { Rooms } from './Modules/Admin/Rooms';
import { RegisterRoom } from './Components/Layout/RegisterRoom/RegisterRoom';
import { UpdateRooms } from './Components/Layout/UpdateRooms/UpdateRooms';
import { Goods } from './Modules/Admin/Goods';
import { Reports } from './Modules/Admin/Reports';
import { Inventory } from './Modules/Admin/Inventory';
import { GoodsConcierge } from './Modules/Concierge/GoodsConcierge';
import { InventoryConcierge } from './Modules/Concierge/InventoryConcierge';
import { RoomsConcierge } from './Modules/Concierge/RoomsConcierge';
import { GoodsInstrutor } from './Modules/Instructor/GoodsInstrutor';
import { ReportsInstructor } from './Modules/Instructor/ReportsInstructor';
import { RoomsInstructor } from './Modules/Instructor/RoomsInstructor';
import { Updates } from './Modules/Instructor/Updates';


function App() {
  return (
    <UserProvider>
        <Routes>
          <Route path='/'  element={<Home/>}/>
          <Route path='/login' element={<Login/>}/>
          <Route path='/RememberPassword' element={<RememberPassword/>}/>
          <Route path='/RecoverPassword/' element={<RecoverPassword/>}/>
          <Route path='/FooterHome' element={<FooterHome/>}/>
          <Route path='/HeaderUser' element={<HeaderUser/>}/>
          <Route path='/Profile' element={<Profile/>}/>
          <Route path='/ManageUsers' element={<Users/>}/>
          <Route path='/RegisterUser' element={<RegisterUser/>}/>
          <Route path='/UserProfile' element={<UserProfile/>}/>
          <Route path='/ManageRooms' element={<Rooms/>}/>
          <Route path='/RegisterRoom' element={<RegisterRoom/>}/>
          <Route path='/UpdateRooms' element={<UpdateRooms/>}/>
          <Route path='/ManageGoods/:user' element={<Goods/>}/>
          <Route path='/ManageReports' element={<Reports/>}/>
          <Route path='/ManageInventory' element={<Inventory/>}/>
          <Route path='/Goods' element={<GoodsConcierge/>}/>
          <Route path='/Inventory' element={<InventoryConcierge/>}/>
          <Route path='/Rooms' element={<RoomsConcierge/>}/>
          <Route path='/Goods/User' element={<GoodsInstrutor/>}/>
          <Route path='/Inventory/User' element={<Inventory/>}/>
          <Route path='/Rooms/User' element={<RoomsInstructor/>}/>
          <Route path='/Reports/User' element={<ReportsInstructor/>}/>
          <Route path='/Updates/User' element={<Updates/>}/>
          <Route path='*' element={<Login/>}/>
        </Routes>
    </UserProvider>
  );
}

export default App;