/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { decodeToken } from 'react-jwt';
import Swal from 'sweetalert2';
import { url } from '../../ApiRoutes';
import { styles } from '../../styles'
import { userContext } from '../../UserProvider/UserProvider';

export const RegisterUpdate = () => {
  const [date,setDate] = useState('');
  const [time,setTime] = useState('');
  const {user} = useContext(userContext);
  const [update,setUpdate] = useState('');
  const [room,setRoom] = useState('default');
  const token = decodeToken(user.data.token);
  const [roomsNumbers, setRoomsNumbers] = useState([]);
  
  const config = {
    headers: { Authorization: `Bearer ${user.data.token}` }
  };
  

  const createUpdate = () => {
    if(date === '' || time === '' || update === '' || room === ''  || token === ''){
      Swal.fire({title: '¡Error!', text:`Campos obligatorios vacíos`, icon: 'error', confirmButtonText: 'Ok'})
    }else{
      axios.post(url.CreateUpdate, {
        "date": date,
        "time":time,
        "descriptionCreate":update,
        "photo": '',
        "modeId":'M01',
        "number":room, 
        "documentCreate":token.userName
      }, config).then((response)=>{
        if(response.data === 'SUCCESSFULLY_REGISTERED'){
          setDate('');
          setTime('');
          setUpdate('');
          setRoom('default');
          Swal.fire({title: 'Se registro novedad', text:`La novedad del ambiente ${room} se creo correctamente`, icon: 'success', confirmButtonText: 'Ok'})
        }
      })
      .catch(ex=>{
        console.log(ex.response);
      })
    }
  }

  const roomsNumber = (() => {
    axios.get(url.RoomsNumbers, config)
    .then(response => {
      setRoomsNumbers(response.data.listRooms)
    })
    .catch(ex => {
      console.log(ex);
    })
  })

  useEffect(()=> {
    roomsNumber()
  },[])

  
  return (
    <div className='w-full h-full flex justify-evenly items-center flex-col'>
      <div className='w-[80%] h-[5rem]  flex justify-center items-center border-b-2'>
        <h1 className='text-[2rem]' >Generar Novedad</h1>
      </div>
        <div className='flex justify-evenly w-[50%]'>
          <div className='flex flex-col gap-8'>
            <div>                
              <label className='text-[1.2rem]'>Número de ambiente</label>
              <select value={room} className={`${styles.input}`} onChange={(e)=>setRoom(e.target.value)}>
                <option value="default" disabled="disabled">Seleccionar ambiente</option>
                {roomsNumbers.map((element) => 
                <>
                  <option value={`${element.number}`}>Ambiente {element.number}</option>
                </>
                )}
              </select>
            </div>
            <div className='flex flex-col'>
              <label className='text-[1.2rem]'>Fecha</label>
              <input type="date" className={`${styles.input}`} name="" id="" onChange={(e)=>setDate(e.target.value)} />
            </div>
          </div>
          <div className='flex flex-col gap-8'>
            <div>
              <label className='text-[1.2rem]'>Hora</label>
              <input type="time" className={`${styles.input}`} name="" id="" onChange={(e)=> setTime(e.target.value)} />
            </div>
            <div>
              <label className='text-[1.2rem]'>Foto</label>
              <input type="file" className={`${styles.fileInput}`} name="" id="" />
            </div>
          </div>
        </div>
        <div className='w-[100%] h-[22rem] flex justify-center items-center flex-col gap-4'>
          <label className='text-[1.2rem]'>Novedad</label>
          <textarea className={`${styles.input} w-[50%] h-full resize-none `}  onChange={(e)=>setUpdate(e.target.value)} name="" id="" cols="30" rows="30"></textarea>
        </div>
        
        <button className={`${styles.formButton}`} onClick={createUpdate}>Generar novedad</button>
      </div>
  )
}
