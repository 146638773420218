import React, {useState, useEffect} from 'react';
import './InputSearch.css';
import Swal from 'sweetalert2';
import {MagnifyingGlass} from "@vectopus/atlas-icons-react";

export const InputSearch = ({type, options, placeholder, searchMethod}) => {
  console.log(searchMethod);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('');

  const keyUpSearch=(event)=>{
    if (event.keyCode===13) {
      if (search === ''){
        Swal.fire({title: '¡Error!',text: 'Campo vacío',icon: 'error',confirmButtonText: 'Ok'})
        searchMethod(search)
      } else {
        searchMethod(search)
      }
    }
  }

  const keyUpFilterSearch=(event)=>{
    if (event.keyCode===13) {
      if (search === ''){
        Swal.fire({title: '¡Error!',text: 'Campo vacío',icon: 'error',confirmButtonText: 'Ok'})
        searchMethod(search, filter)
      } else {
        searchMethod(search, filter)
      }
    }
  }

  return (
    <div className={`w-full flex justify-center gap-3`}>
      {
        options.length === 0 ? 
          <div className='relative'>
            <input type={type} className='h-[3rem] w-[63rem] pl-[2.5rem] border-2 border-[#e7ebda] rounded outline-none bg-[#f4f4f4] box-border' placeholder={placeholder} onChange={(e) =>{setSearch(e.target.value); searchMethod(e.target.value)}} onKeyUp={(e=>keyUpSearch(e))} />
            <MagnifyingGlass className='absolute left-[0.75rem] top-[50%] translate-y-[-50%]' size={18} color={'#515151'}/>
          </div>        
        :
        <>
        <div className='relative'>
          <input type={type} className='h-[3rem] w-[52.5rem] pl-[2.5rem] border-2 border-[#e7ebda] rounded outline-none bg-[#f4f4f4] box-border' placeholder={placeholder} onChange={(e) =>{setSearch(e.target.value)}} onKeyUp={(e=>keyUpFilterSearch(e))}  />
          <MagnifyingGlass className='absolute left-[0.75rem] top-[50%] translate-y-[-50%]' size={18} color={'#515151'}/>
        </div>
        <select name="optionsFilter" id="" className='h-[3rem] cursor-pointer outline-none rounded-md border-[#e7ebda] border-2 bg-[#f4f4f4]' onChange={(e)=>{setFilter(e.target.value)}}>
          <option value="default" selected disabled>Filtros</option>
          {options?.map((element, index) => (
            <option value={`${index}`}>{element}</option>
          ))}
        </select>
      </>
      }
      <button className='btn'>Buscar</button>
    </div>
  )
}
