import React from 'react';
import { styles } from '../../styles';
import {Trash} from "@vectopus/atlas-icons-react";


export const UpdateInformation = ({updateInformation}) => {
  return (
    <div className={`${styles.goodInformation}`}>
      <p className={`w-[4rem] text-center`}>{updateInformation.updateId}</p>
      <p className={`w-[7rem] text-center truncate`}>{updateInformation.documentCreate}</p>
      <p className={`w-[7rem] text-center truncate`}>{updateInformation.descriptionCreate}</p>
      <p className={`w-[7rem] text-center truncate`}>{updateInformation.date}</p>
      <p className={`w-[5rem] text-center`}>{updateInformation.time}</p>
      <button className={`w-[4rem] flex justify-center items-center`}><Trash size={24} color={'#ff2825'} /></button>
    </div>
  )
}
