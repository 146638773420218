/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import Swal from 'sweetalert2';
import { styles } from '../../Components/styles';
import { Header } from '../../Components/Layout/Header/Header';
import React, { useContext, useEffect, useState } from 'react';
import { FooterHome } from '../../Components/UI/FooterHome/FooterHome';
import { Dashboard } from '../../Components/Layout/Dashboard/Dashboard';
import { userContext } from '../../Components/UserProvider/UserProvider';
import { RegisterGood } from '../../Components/Layout/RegisterGood/RegisterGood';
import { url } from '../../Components/ApiRoutes';
import { HeaderDashboard } from '../../Components/UI/HeaderDashboard/HeaderDashboard';
import { GoodInformationAdmin } from '../../Components/UI/GoodInformationAdmin/GoodInformationAdmin';
import { UpdateGood } from '../../Components/Layout/UpdateGood/UpdateGood';
import {MagnifyingGlass} from "@vectopus/atlas-icons-react";


export const Goods = () => {

  const [data, setData] = useState([]);
  const [active, setActive] = useState('');
  const [filter, setFilter] = useState('');
  const [search, setSearch] = useState(' ');
  const [optionData,setOptionData] = useState('1');
  const [updateData, setUpdateData] = useState([])
  const {user} = useContext(userContext);
  const setNumberOption = ((event)=>{
    setOptionData(event.target.value)
  })

  const config = {
    headers: { Authorization: `Bearer ${user.data.token}` }
  };

  const getGoods = () => {
    axios.get(url.GetAllGoods,config)
    .then(response => {
      setData(response.data.getGoods)
    })
    .catch(ex=>{
      console.log(ex.response);
    })
  }

  const setDataToUpdate = ((e,index)=> {
    setUpdateData(data[index])
    setOptionData('3');
  })

  const keyUp=(event)=>{
    if (event.keyCode===13) {
      manageSearch()
    }
  }  
  
  const manageSearch = () => {
    if(filter===''){
      Swal.fire({title: 'Error!', text: 'Debe de seleccionar un filtro', icon:'error', confirmButtonText: 'Ok'})
    } else if (filter==='Número placa'){
      axios.get(url.GetLabels+search)
      .then(response => {
        setData(response.data);
      })
      .catch(ex=>{
        if (ex.response.data.error === 'GOOD_NOT_EXIST') {
          Swal.fire({title: 'Error!', text: 'El bien no existe', icon:'error', confirmButtonText: 'Ok'})
        }
      })
    } else if(filter==='Cuentadante'){
      axios.post(url.FilterGoods+'1',{
        "document": parseInt(search)
      }, config)
      .then(response => {
        console.log(response);
        setData(response.data[0]);
      })
      .catch(ex=>{
        if (ex.response.data.error === 'USER_NOT_EXIST') {
          Swal.fire({title: 'Error!', text: 'El cuentadante no existe', icon:'error', confirmButtonText: 'Ok'})
        }
      })
    } else if(filter==='Tipo elemento'){
      axios.post(url.FilterGoods+'2',{
        "element": search
      }, config)
      .then(response => {
        setData(response.data[0]);
      })
      .catch(ex=>{
        if (ex.response.data.error === 'TYPE_ELEMENT_NOT_EXIST') {
          Swal.fire({title: 'Error!', text: 'El tipo de elemento no existe', icon:'error', confirmButtonText: 'Ok'})
        }
      })
    } else if(filter==='Serial'){
      axios.post(url.FilterGoods+'3',{
        "serial": search
      }, config)
      .then(response => {
        setData(response.data[0]);
      })
      .catch(ex=>{
        if (ex.response.data.error === 'SERIAL_NOT_EXIST') {
          Swal.fire({title: 'Error!', text: 'El serial no existe', icon:'error', confirmButtonText: 'Ok'})
        }
      })
    }
  }

  useEffect(()=>{
    getGoods()
	},[])

  useEffect(()=>{
    if (search === '') {
      getGoods()
    }
	},[search])

  const sizeColumn = {
    column1 : "5rem",
    column2 : "6rem",
    column3 : "7rem",
    column4 : "5rem",
    column5 : "2rem",
    column6 : "7rem",
    column7 : "4rem",
  }

  const headerDashboardInformation = {
    columnText1 : "Placa",
    columnText2 : "Elemento",
    columnText3 : "Descripción",
    columnText4 : "Atributos",
    columnText5 : "Valor",
    columnText6 : "Ubicación",
    columnText7 : "Acciones",
    columnWidth1 : sizeColumn.column1,
    columnWidth2 : sizeColumn.column2,
    columnWidth3 : sizeColumn.column3,
    columnWidth4 : sizeColumn.column4,
    columnWidth5 : sizeColumn.column5,
    columnWidth6 : sizeColumn.column6,
    columnWidth7 : sizeColumn.column7,
  }

  const headerInformation = {
    names: 'Gestionar',
    lastNames: 'Bienes',
		links: [
      {
        url:'/',
        name: 'Inicio'
      },
			{
				url: '',
				name: 'Lista de bienes',
			},
			{
				url: '',
				name: 'Registrar bienes',
			},
		]
  }
  
  return (
    <>
    <div>
      <div className='sm:w-[100%]'>
        <Header data={headerInformation} setNumber={setNumberOption} />
      </div>
        <div className='sm:w-[100%] w-full flex justify-center items-center flex-col'>
          <div className={`${styles.mainContainer}`}>
            {optionData === '1' &&
            <>
              <div className='w-[84%] flex justify-center items-center flex-col'>
                <div className='relative w-[100%] flex justify-center items-center'>
                  <input type="search" className={`${filter===''? "pl-[2.5rem]" : "pl-[11rem]"} h-[3rem] w-full pl-[2.5rem] border-2 border-[#e7ebda] rounded outline-none bg-[#f4f4f4] box-border`} placeholder="Buscar bienes" onClick={()=>setActive('active')} onChange={(e)=>{setSearch(e.target.value)}} onKeyUp={(e) => keyUp(e)}/>
                  <MagnifyingGlass className='absolute left-[0.75rem] top-[50%] translate-y-[-50%]' size={18} color={'#515151'}/>
                  <p className="absolute top-[50%] left-[2.8rem] translate-y-[-50%] font-semibold bg-[#dcdcdc] flex justify-evenly items-center w-[8rem] rounded">{filter} <svg xmlns="http://www.w3.org/2000/svg" className={`${filter===''? "hidden": "icon icon-tabler icon-tabler-x cursor-pointer" }`} onClick={()=>{setFilter('')}} width="18" height="18" viewBox="0 0 24 24" stroke="#ff2825" fill="none"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><line x1="18" y1="6" x2="6" y2="18" /><line x1="6" y1="6" x2="18" y2="18" /></svg></p>
                </div>
                <div className={`${active==='active' ? "flex w-full h-[3rem] items-center justify-evenly" : "hidden"}`}>
                  <p className='font-semibold bg-[#dcdcdc] rounded w-[8rem] text-center p-[0.3rem] cursor-pointer' id='Número placa' onClick={(e)=>{setFilter(e.target.id); setActive('')}}>Número placa</p>
                  <p className='font-semibold bg-[#dcdcdc] rounded w-[8rem] text-center p-[0.3rem] cursor-pointer' id='Serial' onClick={(e)=>{setFilter(e.target.id); setActive('')}}>Serial</p>
                  <p className='font-semibold bg-[#dcdcdc] rounded w-[8rem] text-center p-[0.3rem] cursor-pointer' id="Cuentadante" onClick={(e)=>{setFilter(e.target.id); setActive('')}}>Cuentadante</p>
                  <p className='font-semibold bg-[#dcdcdc] rounded w-[8rem] text-center p-[0.3rem] cursor-pointer' id="Tipo elemento" onClick={(e)=>{setFilter(e.target.id); setActive('')}}>Tipo elemento</p>
                </div>
              </div>
              <div className='w-full flex items-center gap-[2rem] flex-col'>
                <HeaderDashboard headerDashboardInformation={headerDashboardInformation}/>
                {
                  <Dashboard dashboardInformation={
                    data.map((item, index) => 
                      <GoodInformationAdmin goodInformation={item} key={item.labels} index={index} updateData={setDataToUpdate}/>
                  )}/>
                }
              </div>
            </>
            }
            {optionData === '2' && 
            <>
              <RegisterGood/>
            </>
            }
            {optionData === '3' &&
            <>
              <UpdateGood updateData={updateData}/>
            </>
            }
          </div>
          <div className='w-full'>
              <FooterHome/>
            </div>
        </div>
    </div>
    </>
  )
}