import axios from 'axios'
import React, { useState,useContext } from 'react'
import { decodeToken } from 'react-jwt'
import Swal from 'sweetalert2'
import { url} from '../../ApiRoutes'
import { userContext } from '../../UserProvider/UserProvider'

export const UpdateUserPassword = () => {

	const[currentPassword,setCurrentPassword]=useState()
	const[newPassword,setNewpassword]=useState()
	const[confirmPassword,setConfirmpassword]=useState()

	const {user} = useContext(userContext)
  const tokenData = decodeToken(user.data.token)

  const config = {
    headers: { Authorization: `Bearer ${user.data.token}` }
};

const bodyParameters = {
	currentPassword:currentPassword,
	newPassword:newPassword
};


const updatePassword = (()=>{
	if (newPassword===confirmPassword) {
		Swal.fire({title: '¿Esta seguro?', text:'Se aplicaran los cambios' ,showDenyButton: true,showCancelButton: true,confirmButtonText: 'Save',denyButtonText: `Don't save`,
		}).then((result) => {
			if (result.isConfirmed) {
				axios.put(url.UpdatePassword+tokenData.userName,bodyParameters,config)
				.then(response => {
					setCurrentPassword('')
					setNewpassword('')
					setConfirmpassword('')
					Swal.fire('Saved!', '', 'success')
				})
				.catch(ex => {
					if (ex.response.data.error === 'PASSWORD_INVALID') {
						Swal.fire({title: 'Error!', text:'Su contraseña no coindice',icon: 'error',confirmButtonText: 'Ok'})
					}
				})
			} else if (result.isDenied) {
				Swal.fire('Changes are not saved', '', 'info')
			}
		})
	}
	else{
		Swal.fire({
			title: 'Error!',
			text: 'Las constraseñas no coinciden',
			icon: 'error',
			confirmButtonText: 'Ok'
		})
	}
})


return (
	<div className='w-full h-screen flex flex-col justify-around items-center' >
			<div className='w-[70%] flex justify-around items-center'>
				<h1 className='text-[2.5rem]'>Actualizar contraseña</h1>
			</div>
			<div className='w-[80%] h-[60%] grid grid-cols-2 justify-between'>
				<div className='flex flex-col justify-center items-center'>
					<label className='text-[1.2rem] w-[80%]'>Contraseña antigua</label>
					<input type="password" className='w-[80%] h-[2.5rem] rounded-b-md border-solid border-green-600 border-2 pl-1' placeholder='Contraseña antigua' onChange={(e)=>{setCurrentPassword(e.target.value)}} value={currentPassword} />
				</div>
				<div className='flex flex-col justify-center items-center'>
					<label className='text-[1.2rem] w-[80%]'>Nueva contraseña</label>
					<input type="password" className='w-[80%] h-[2.5rem] rounded-b-md border-solid border-green-600 border-2 pl-1' placeholder='Nueva contraseña' onChange={(e)=>{setNewpassword(e.target.value)}} value={newPassword} />
				</div>
				<div className='flex flex-col justify-center items-center'>
					<label className='text-[1.2rem] w-[80%]'>Confirmar nueva contraseña</label>
					<input type="password" className='w-[80%] h-[2.5rem] rounded-b-md border-solid border-green-600 border-2 pl-1' placeholder='Confirmar nueva contraseña' onChange={(e)=>{setConfirmpassword(e.target.value)}} value={confirmPassword} />
				</div>
				<div className='w-[40%] h-[40%] flex justify-center items-center'>
            <button className='rounded hover:text-white w-40 h-10 text-center bg-[#17C700] text-lg' onClick={updatePassword}>Actualizar</button>
        </div>
			</div>
		</div>
)
}
