/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import { styles } from '../../Components/styles';
import { Header } from '../../Components/Layout/Header/Header';
import { FooterHome } from '../../Components/UI/FooterHome/FooterHome';
import { Dashboard } from '../../Components/Layout/Dashboard/Dashboard';
import { userContext } from '../../Components/UserProvider/UserProvider';
import { url } from '../../Components/ApiRoutes';
import { HeaderDashboard } from '../../Components/UI/HeaderDashboard/HeaderDashboard'
import { GoodInformation } from '../../Components/UI/GoodInformation/GoodInformation';
import {MagnifyingGlass} from "@vectopus/atlas-icons-react";
import { InputSearch } from '../../Components/UI/InputSearch/InputSearch';

export const GoodsInstrutor = () => {
  const [data, setData] = useState([]);
  const [optionData,setOptionData] = useState('1');
  const {user} = useContext(userContext);
  const setNumberOption = ((event)=>{
    setOptionData(event.target.value)
  })

  const config = {
    headers: { Authorization: `Bearer ${user.data.token}` }
  };

  const getGoods = () => {
    axios.get(url.AllGoods,config)
    .then(response => {
      setData(response.data.getGoods)
    })
    .catch(ex=>{
      console.log(ex.response);
    })
  } 
  
  const manageSearch = (search, filter) => {
    if(filter === '') {
      Swal.fire({title: 'Error!', text: 'Debe de seleccionar un filtro', icon:'error', confirmButtonText: 'Ok'})
    } else if (filter==='0'){
      axios.get(url.GetLabels+search)
      .then(response => {
        setData(response.data);
      })
      .catch(ex=>{
        if (ex.response.data.error === 'GOOD_NOT_EXIST') {
          Swal.fire({title: 'Error!', text: 'El bien no existe', icon:'error', confirmButtonText: 'Ok'})
        }
      })
    } else if(filter==='2'){
      axios.post(url.FilterGoods+'1',{
        "document": parseInt(search)
      }, config)
      .then(response => {
        console.log(response);
        setData(response.data[0]);
      })
      .catch(ex=>{
        if (ex.response.data.error === 'USER_NOT_EXIST') {
          Swal.fire({title: 'Error!', text: 'El cuentadante no existe', icon:'error', confirmButtonText: 'Ok'})
        }
      })
    } else if(filter==='3'){
      axios.post(url.FilterGoods+'2',{
        "element": search
      }, config)
      .then(response => {
        setData(response.data[0]);
      })
      .catch(ex=>{
        if (ex.response.data.error === 'TYPE_ELEMENT_NOT_EXIST') {
          Swal.fire({title: 'Error!', text: 'El tipo de elemento no existe', icon:'error', confirmButtonText: 'Ok'})
        }
      })
    } else if(filter==='1'){
      axios.post(url.FilterGoods+'3',{
        "serial": search
      }, config)
      .then(response => {
        setData(response.data[0]);
      })
      .catch(ex=>{
        if (ex.response.data.error === 'SERIAL_NOT_EXIST') {
          Swal.fire({title: 'Error!', text: 'El serial no existe', icon:'error', confirmButtonText: 'Ok'})
        }
      })
    }
  }

  useEffect(()=>{
    getGoods()
	},[])

  const sizeColumn = {
    column1 : "5rem",
    column2 : "6rem",
    column3 : "7rem",
    column4 : "5rem",
    column5 : "2rem",
    column6 : "7rem",
    column7 : "4rem",
  }

  const headerDashboardInformation = {
    columnText1 : "Placa",
    columnText2 : "Elemento",
    columnText3 : "Descripción",
    columnText4 : "Atributos",
    columnText5 : "Valor",
    columnText6 : "Ubicación",
    columnText7 : "Acciones",
    columnWidth1 : sizeColumn.column1,
    columnWidth2 : sizeColumn.column2,
    columnWidth3 : sizeColumn.column3,
    columnWidth4 : sizeColumn.column4,
    columnWidth5 : sizeColumn.column5,
    columnWidth6 : sizeColumn.column6,
    columnWidth7 : sizeColumn.column7,
  }

  const headerInformation = {
    names: 'Gestionar',
    lastNames: 'Bienes',
		links: [
      {
        url:'/',
        name: 'Inicio'
      },
			{
				url: '',
				name: 'Lista de bienes',
			},
		]
  }

  return (
    <>
    <div>
      <div className='sm:w-[100%]'>
        <Header data={headerInformation} setNumber={setNumberOption} />
      </div>
      <div className='sm:w-[100%] w-full flex justify-center items-center flex-col'>
        <div className={`${styles.mainContainer}`}>
          {optionData === '1' &&
          <>
            <div>
              <InputSearch type='text' options={['Número de placa', 'Serial', 'Cuentadante', 'Tipo elemento']} placeholder='Buscar bienes' searchMethod={manageSearch}/>
            </div>
            <div className='w-full flex items-center gap-[2rem] flex-col'>
              <HeaderDashboard headerDashboardInformation={headerDashboardInformation}/>
              {
                <Dashboard dashboardInformation={
                  data?.map((good, index) => 
                  <>
                    <GoodInformation goodInformation={good} key={index} id={index}/>
                  </>
                )}/>
              }
            </div>
          </>
          }
          </div>
          <div className='w-full'>
            <FooterHome/>
          </div>
        </div>
      </div>
    </>
  )
}
