import axios from 'axios';
import React, { useContext, useState } from 'react';
import Swal from 'sweetalert2';
import { url } from '../../ApiRoutes';
import { userContext } from '../../UserProvider/UserProvider';
import { CheckCircle, Trash } from '@vectopus/atlas-icons-react';

export const UserInformation = ({sizeColumn, userInformation, getIndexUser, id}) => {
  
  const {user} = useContext(userContext);
  const [modeId,setModeId]=useState(userInformation.mode.modeName)


  const config = {
    headers: { Authorization: `Bearer ${user.data.token}` }
  };

  const changeState = () => {
    Swal.fire({
      title: '¿Quiere guardar los cambios?',
      showDenyButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Guardar',
      confirmButtonColor: '#03b500',
      denyButtonText: `No guardar`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios.put(url.UpdateStateUser+userInformation.userName, {
          modeId:userInformation.modeId
        }, config)
        .then(response => {
          if (modeId === 'Activo') {
            setModeId('Inactivo')
          }
          else {
            setModeId('Activo')
          }
        })
        .catch(ex =>{
          console.log('ERROR'+ex);
        })
      } else if (result.isDenied) {
        Swal.fire('Los datos no se actualizaron', '', 'info')
      }
    })
  }

  return (
    <div className={`h-auto bg-[#ececec] rounded w-[97%] flex items-center justify-around gap-[1rem]`}>
      <img src={userInformation.photo} alt="user_photo" className={`w-[2.5rem] h-[2.5rem] rounded-full`} />
      <p className={`w-[7rem] text-center cursor-pointer`} id={id} onClick={(e)=>{getIndexUser(e)}}>{userInformation.names} {userInformation.lastNames}</p>
      <p className={`w-[7rem] text-center truncate`} title={userInformation.email}>{userInformation.email}</p>
      <p className={`w-[9rem] text-center`}>{userInformation.role.roleName}</p>
      <p className={`w-[8rem] text-center`}>{userInformation.trainingCenter}</p>
      <p className={`w-[6rem] text-center`}>{userInformation.mode.modeName}</p>
      {modeId === 'Activo'?
        <button className={`w-[4rem] flex justify-center items-center`} onClick={changeState}><Trash size={24} color={'#ff2825'}/></button>
        : 
        <button className={`w-[4rem] flex justify-center text-center`} onClick={changeState}><CheckCircle size={24} color={'#00b341'}/></button>
      }
    </div>
    
  )
}
