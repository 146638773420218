import React from 'react';
import '../Dashboard/Dashboard.css';

export const Dashboard = ({dashboardInformation}) => {
  return (
    <div className={`w-[84%] h-[40rem] bg-[#ccc] rounded flex flex-col gap-[0.5rem] justify-start items-center p-[1rem] container-scroll overflow-auto`}>
      {dashboardInformation}
    </div>
  )
}
