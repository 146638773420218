// Ulrs para con back local 
export const url = {
  Login : 'http://localhost:3308/api/users/login',
  TotalRooms : 'http://localhost:3308/api/rooms/totalRooms',
  TotalGoods: 'http://localhost:3308/api/goods/totalGoods',
  TotalCaretaker : 'http://localhost:3308/api/users/totalCaretaker',
  TotalInstructors : 'http://localhost:3308/api/users/totalInstructors',
  UserAllData : 'http://localhost:3308/api/users/userData?document=',
  AllGoods : 'http://localhost:3308/api/goods/',
  RegisterRoom : 'http://localhost:3308/api/rooms/registerRoom',
  GetLabels : 'http://localhost:3308/api/goods/label?label=',
  GetUsers : 'http://localhost:3308/api/users/user?document=',
  AllUsers : 'http://localhost:3308/api/users/?document=',
  AllAdmin : 'http://localhost:3308/api/users/administrators/?document=',
  GetAllGoods : 'http://localhost:3308/api/goods/',
  GetUsersGood : 'http://localhost:3308/api/goods/user?document=',
  FilterGoods : 'http://localhost:3308/api/goods/filter?optionFilter=',
  RegisterRoomsByXlsx : 'http://localhost:3308/api/uploadXlsx/rooms',
  ConfirmEmail : 'http://localhost:3308/api/users/confirmEmail?email=',
  SendEmail : 'http://localhost:3308/api/sendEmail?email=',
  UpdateStateUser : 'http://localhost:3308/api/users/updateStateUser?document=',
  UpdateUserData : 'http://localhost:3308/api/users/updateUser?document=',
  UpdatePassword : 'http://localhost:3308/api/users/changePassword?document=',
  AllRooms : `http://localhost:3308/api/rooms/rooms`,
  MultiRooms : `http://localhost:3308/api/rooms/multiRooms`,
  RoomsInventory : 'http://localhost:3308/api/inventory?number=',
  ResetPassword : 'http://localhost:3308/api/users/resetPassword',
  UpdateRooms : 'http://localhost:3308/api/rooms/updateRoom?currentNumber=',
  InserUsersByXlsx : 'http://localhost:3308/api/uploadXlsx/users',
  InserRoomsByXlsx : 'http://localhost:3308/api/uploadXlsx/rooms',
  InserGoodsByXlsx : 'http://localhost:3308/api/uploadXlsx/goods',
  RegisterUser : 'http://localhost:3308/api/users/registerUsers',
  RegisterGood : 'http://localhost:3308/api/goods/registerGood',
  GenerateReportGoods : 'http://localhost:3308/api/reports/reportGoods?document=',
  GenerateReportRooms : 'http://localhost:3308/api/reports//reportRooms?number=',
  GenerateReportRoomsUpdates : 'http://localhost:3308/api/reports//reportRoomsUpdate?number=',
  AsociateGoodToUser : 'http://localhost:3308/api/inventory/inventoryUser?document=',
  AsociateGoodToRoom : 'http://localhost:3308/api/inventory/inventoryRoom?number=',
  UpdateGood : 'http://localhost:3308/api/goods/updateGood?label=',
  UpdateGoodMode : 'http://localhost:3308/api/goods/updateGoodMode?label=',
  RoomsNumbers : 'http://localhost:3308/api/rooms/listRooms',
  CreateUpdate : 'http://localhost:3308/api/updates/registerUpdate',
  UpdateFilter : 'http://localhost:3308/api/updates/filterHistoricalUpdate?optionFilter=',
}

// export const urlLabels = 'http://localhost:3308/api/goods/label?label='
// export const urlUsers = 'http://localhost:3308/api/users/user?document='
// export const urlAllUsers = 'http://localhost:3308/api/users/?document='
// export const urlAllAdmin = 'http://localhost:3308/api/users/administrators/?document='
// export const urlConfirmEmail = 'http://localhost:3308/api/users/confirmEmail?email='
// export const urlSendEmail = 'http://localhost:3308/api/sendEmail?email='
export const urlUserAllData = 'http://localhost:3308/api/users/userData?document='
export const urlUpdateStateUser = 'http://localhost:3308/api/users/updateStateUser?document='
export const urlUpdateUserData = 'http://localhost:3308/api/users/updateUser?document='
export const urlUpdatePassword = 'http://localhost:3308/api/users/changePassword?document='
export const urlAllRooms = `http://localhost:3308/api/rooms/rooms`
export const urlMultiRooms = `http://localhost:3308/api/rooms/multiRooms`
export const urlRoomsGoods = `http://localhost:3308/api/rooms/room?number=`
export const urlGetUsersGood = 'http://localhost:3308/api/goods/user?document='
export const urlUpdateRooms = 'http://localhost:3308/api/rooms/updateRoom?currentNumber='
export const urlInserUsersByXlsx = 'http://localhost:3308/api/uploadXlsx/users'
export const urlInserRoomsByXlsx = 'http://localhost:3308/api/uploadXlsx/rooms'
export const urlInserGoodsByXlsx = 'http://localhost:3308/api/uploadXlsx/goods'
export const urlRegisterUser = 'http://localhost:3308/api/users/registerUsers'
export const urlAllGoods = 'http://localhost:3308/api/goods/'
export const urlRegisterGood = 'http://localhost:3308/api/goods/registerGood'
export const urlGenerateReportGoods = 'http://localhost:3308/api/reports/reportGoods?document='
export const urlGenerateReportRooms = 'http://localhost:3308/api/reports//reportRooms?number='
export const urlGenerateReportRoomsUpdates = 'http://localhost:3308/api/reports//reportRoomsUpdate?number='
export const urlFilterGoods = 'http://localhost:3308/api/goods/filter?optionFilter='
export const urlResetPassword = 'http://localhost:3308/api/users/resetPassword'
export const urlAsociateGoodToUser = 'http://localhost:3308/api/inventory/inventoryUser?document='
export const urlAsociateGoodToRoom = 'http://localhost:3308/api/inventory/inventoryRoom?number='
export const urlRoomsInventory = 'http://localhost:3308/api/inventory?number='
export const urlUpdateGood = 'http://localhost:3308/api/goods/updateGood?label='
export const urlUpdateGoodMode = 'http://localhost:3308/api/goods/updateGoodMode?label='
export const urlRoomsNumbers = 'http://localhost:3308/api/rooms/listRooms'
export const urlCreateUpdate = 'http://localhost:3308/api/updates/registerUpdate'
export const urlUpdateFilter = 'http://localhost:3308/api/updates/filterHistoricalUpdate?optionFilter='

// export const urlLogin = 'http://10.199.0.79:3308/api/users/login'
// export const urlRooms = `http://10.199.0.79:3308/api/rooms/registerRoom`
// export const urlLabels = 'http://10.199.0.79:3308/api/goods/label?label='
// export const urlUsers = 'http://10.199.0.79:3308/api/users/user?document='
// export const urlAllUsers = 'http://10.199.0.79:3308/api/users/?document='
// export const urlAllAdmin = 'http://10.199.0.79:3308/api/users/administrators/?document='
// export const urlConfirmEmail = 'http://10.199.0.79:3308/api/users/confirmEmail?email='
// export const urlSendEmail = 'http://10.199.0.79:3308/api/sendEmail?email='
// export const urlUserAllData = 'http://10.199.0.79:3308/api/users/userData?document='
// export const urlUpdateStateUser = 'http://10.199.0.79:3308/api/users/updateStateUser?document='
// export const urlUpdateUserData = 'http://10.199.0.79:3308/api/users/updateUser?document='
// export const urlUpdatePassword = 'http://10.199.0.79:3308/api/users/changePassword?document='
// export const urlAllRooms = `http://10.199.0.79:3308/api/rooms/rooms`
// export const urlRoomsGoods = `http://10.199.0.79:3308/api/rooms/room?number=`
// export const url = `http://10.199.0.79:3308/api/users/resetPassword`
// export const urlGetUsersGood = 'http://10.199.0.79:3308/api/goods/user?document='
// export const urlUpdateRooms = 'http://10.199.0.79:3308/api/rooms/updateRoom?currentNumber='
// export const urlInserUsersByXlsx = 'http://10.199.0.79:3308/api/uploadXlsx/users'
// export const urlInserRoomsByXlsx = 'http://10.199.0.79:3308/api/uploadXlsx/rooms'
// export const urlInserGoodsByXlsx = 'http://10.199.0.79:3308/api/uploadXlsx/goods'
// export const urlRegisterUser = 'http://10.199.0.79:3308/api/users/registerUsers'
// export const urlAllGoods = 'http://10.199.0.79:3308/api/goods/'
// export const urlRegisterGood = 'http://10.199.0.79:3308/api/goods/registerGood'
// export const urlGenerateReportGoods = 'http://10.199.0.79:3308/api/reports/reportGoods?document='
// export const urlGenerateReportRooms = 'http://10.199.0.79:3308/api/reports//reportRooms?number='
// export const urlGenerateReportRoomsUpdates = 'http://10.199.0.79:3308/api/reports//reportRoomsUpdate?number='
// export const urlFilterGoods = 'http://10.199.0.79:3308/api/goods/filter?optionFilter='
// export const urlResetPassword = 'http://10.199.0.79:3308/api/users/resetPassword'
// export const urlAsociateGoodToUser = 'http://10.199.0.79:3308/api/inventory/inventoryUser?document='
// export const urlAsociateGoodToRoom = 'http://10.199.0.79:3308/api/inventory/inventoryRoom?number='
// export const urlRoomsInventory = 'http://10.199.0.79:3308/api/inventory?number='
// export const urlUpdateGood = 'http://10.199.0.79:3308/api/goods/updateGood?label='
// export const urlUpdateGoodMode = 'http://10.199.0.79:3308/api/goods/updateGoodMode?label='
// export const urlRoomsNumbers = 'http://10.199.0.79:3308/api/rooms/listRooms'
// export const urlCreateUpdate = 'http://10.199.0.79:3308/api/updates/registerUpdate'