import axios from 'axios';
import { styles } from '../../styles';
import { url } from '../../ApiRoutes';
import React, { useState, useContext } from 'react';
import { userContext } from '../../UserProvider/UserProvider';
import { ButtonUploadFile } from '../../UI/ButtonUploadFile/ButtonUploadFile';
import Swal from 'sweetalert2';

export const RegisterRoom = () => {

  const [number, setNumber]=useState('')
  const [type, setType]=useState('')
  const [roomName, setroomName]=useState('')
  const [description, setDescription]=useState('')
  const [multiRooms, setMultirooms]=useState('')
  const {user} = useContext(userContext)
  const formData = new FormData()
  const [file, setFile] = useState()

  const configMultipart = {
    headers: {
      'content-type': 'multipart/form-data',
    }}

  const captureExcel = (e) => {
    setFile(e.target.files[0])
  }

  const insertfile = () => {
    if (file !== undefined){
      formData.append('excel', file);
      formData.append('fileName', file.name);
      axios.post(url.RegisterRoomsByXlsx, formData, configMultipart)
      .then(response => {
        window.location.reload()
      })
      .catch(ex => {
      })
    } else {
      Swal.fire({title: 'Error!', text: 'No se selecciono archivo', icon:'error', confirmButtonText: 'Ok'})
    }
  }


  const config = {
    headers: { Authorization: `Bearer ${user.data.token}` }
  };

  const registerRooms = () => {
    if (number !== '' || type !== '' || roomName !== '' || description !== '' || multiRooms !== '') {
      axios.post(url.RegisterRoom,{
        "number":number,
        "type":type, 
        "roomName":roomName, 
        "description":description,
        "multiRooms":multiRooms
      },config)
      .then(response => {
        console.log(response.data.data);
        setNumber('')
        setType('')
        setroomName('')
        setDescription('')
      })
      .catch(ex=>{
        console.log('ERROR'+ex.response);
      })
    } else {
      Swal.fire({title: 'Error!', text: 'Campos vacios', icon:'error', confirmButtonText: 'Ok'})

    }
  };

  return (
    <>
      <div className={`w-full h-[60rem] flex flex-col justify-evenly  items-center`}>
        <div className={`${styles.titleContainer} border-b-2`}>
          <h1 className={`${styles.title}`}>Registro ambientes</h1>
        </div>
        <div className={`w-[80%] h-[40%] flex justify-center sm:gap-[3rem] items-center sm:flex-row flex-col`} >
          <div className='flex flex-col h-[15rem] justify-evenly'>
            <input type="number" className={`${styles.input}`} placeholder="Número"  onChange={(e)=>setNumber(e.target.value)} value={number}/>
            <input type="text" className={`${styles.input}`} placeholder="Tipo"  onChange={(e)=>setType(e.target.value)} value={type}/>
            <input type="text" className={`${styles.input}`} placeholder="Nombre"onChange={(e)=>setroomName(e.target.value)} value={roomName}/>
          </div>
          <div className='flex flex-col h-[15rem] sm:justify-evenly gap-7 sm:gap-0 '>
            <input type="text" className={`${styles.input}`} placeholder="Descripción" onChange={(e)=>setDescription(e.target.value)} value={description}/>
            <select name="employmentRelationship" className={`${styles.input}`} onChange={(e)=>setMultirooms(e.target.value)}>
              <option selected disabled>Cuentadante Múltiple</option>
              <option>Si</option>
              <option>No</option>
            </select>
          </div>
        </div>
        <div className='text-center'>
          <button className={`${styles.formButton}`} onClick={registerRooms}>Registrar</button>
        </div>
        <ButtonUploadFile captureExcel={captureExcel}/>
        <div className={`${styles.formButtonContainer}`}>
          <button className={`${styles.formButton}`} onClick={insertfile}>Registrar archivo</button>
        </div>
      </div>
    </>
  )
}
