import axios from 'axios';
import React, { useState, useContext } from 'react';
import Swal from 'sweetalert2';
import { url } from '../../ApiRoutes';
import { userContext } from '../../UserProvider/UserProvider';
import {CheckCircle, Trash, PencilEdit} from "@vectopus/atlas-icons-react";



export const GoodInformationAdmin = ({goodInformation, updateData, index}) => {

  const {user} = useContext(userContext);
  const [modeId,setModeId]=useState(goodInformation.modeId)

  const config = {
    headers: { Authorization: `Bearer ${user.data.token}` }
  };

  const changeState = () => {
    Swal.fire({
      title: '¿Quiere guardar los cambios?',
      showDenyButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Guardar',
      confirmButtonColor: '#03b500',
      denyButtonText: `No guardar`,
    }).then((result) => {
      if(result.isConfirmed){
        axios.put(url.UpdateGoodMode+goodInformation.labels,{
          modeId:goodInformation.modeId
        }, config)
        .then(response => {
          if (modeId === 'M01') {
            setModeId('M02')
          }
          else {
            setModeId('M01')
          }
        })
        .catch(ex =>{
          console.log('ERROR'+ex);
        })
      } else if (result.isDenied) {
        Swal.fire('Los datos no se actualizaron', '', 'info') 
      }
    })
  }

  return (
    <div className={`h-[2.5rem] bg-[#ececec] rounded w-[97%] flex items-center justify-around gap-[1rem]`}>
      <p className={`w-[3rem] text-center`}>{goodInformation.labels}</p>
      <p className={`w-[6rem] text-center truncate`} title={goodInformation.element}>{goodInformation.element}</p>
      <p className={`w-[7rem] text-center truncate`} title={goodInformation.classDescription}>{goodInformation.classDescription}</p>
      <p className={`w-[5rem] text-center truncate`} title={goodInformation.atributtes}>{goodInformation.atributtes}</p>
      <p className={`w-[4rem] text-center`}>{goodInformation.purchasingValue}</p>
      <p className={`w-[7rem] text-center`}>{goodInformation.currentLocation}</p>
      <div className={`w-[4rem] flex items-center gap-[1rem]`}>
      {modeId === 'M01'?
        <button className={`w-[4rem] flex justify-center items-center`} onClick={changeState} title='Inactivar'><Trash size={24} color={'#ff2825'} /></button>
        : 
        <button className={`w-[4rem] flex justify-center text-center`} onClick={changeState} title='Activar'><CheckCircle size={24} color={'#00b341'}/></button>
      }
        <button className={`w-[4rem] flex justify-center items-center`} onClick={e => updateData(e,index)}><PencilEdit size={20}/></button>
      </div>
  </div>
  )
}