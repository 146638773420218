import React from 'react';
import './GoodsInventory.css'

export const GoodsInventory = ({goodInformation, goodList}) => {
  return (
    <div className={`h-auto bg-[#ececec] rounded w-[97%] flex items-center justify-around gap-[1rem]`}>
      <p className={`w-[3rem] text-center`}>{goodInformation.labels}</p>
      <p className={`w-[6rem] text-center truncate`} title={goodInformation.element}>{goodInformation.element}</p>
      <p className={`w-[7rem] text-center truncate`} title={goodInformation.classDescription}>{goodInformation.classDescription}</p>
      <p className={`w-[5rem] text-center truncate`} title={goodInformation.atributtes}>{goodInformation.atributtes}</p>
      <p className={`w-[4rem] text-center`}>{goodInformation.purchasingValue}</p>
      <p className={`w-[7rem] text-center`}>{goodInformation.currentLocation}</p>
      <input className='check cursor-pointer' type="checkbox" name="" id="" value={goodInformation.labels}  onChange={(e)=>goodList(e)}/>
  </div>
  )
}
