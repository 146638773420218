import axios from 'axios';
import React, { useContext, useState } from 'react'
import Swal from 'sweetalert2';
import { url } from '../../ApiRoutes';
import { userContext } from '../../UserProvider/UserProvider';
import {XmarkCircle, TriangleExclamation, MagnifyingGlass} from "@vectopus/atlas-icons-react";


export const ModalAssociating = ({labels,onClose,visible}) => {

  const {user} = useContext(userContext);
  const close=()=>{onClose(false)};
  const [option, setOption] = useState(0)
  const [number, setNumber] = useState()
  if(!visible)return null;
  
  const config = {
    headers: { Authorization: `Bearer ${user.data.token} `}
  };

  const changeInput = (type) => {
    if(type === "Ambiente"){
      setOption(1)
    }
    else if(type==="Cuentadante"){
      setOption(2)
    }
  }

  const deleteLabels = (element) => {
    let index = labels.indexOf(element)
    labels.splice(index,1)
  }
  
  const asociateGoods = () => {
    if (option === 1) {
      axios.put(url.AsociateGoodToRoom+number,{
        arrayGoods:labels 
      },config)
      .then(response => {
        if (response.data === 'SUCESSFUL_CHANGE') {
          Swal.fire({title: 'Se ha asociado correctamente', text:`Se ha asociado al ambiente ${number}` , confirmButtonText: 'Ok'})
          .then((result) => {
			      if (result.isConfirmed) {
              window.location.reload()
            }
          })
        }
      })
      .catch(ex => {
        if (ex.response.data.error === 'ROOM_NOT_EXIST') {
          Swal.fire({title: 'Error!',text: 'El ambiente no existe.',icon: 'error',confirmButtonText: 'Ok'})
        }
      })
    }
    else if (option === 2) {
      axios.put(url.AsociateGoodToUser+number,{
        arrayGoods:labels
      },config)
      .then(response => {
        if (response.data === 'SUCESSFUL_CHANGE') {
          Swal.fire({title: 'Se ha asociado correctamente', text:`Se ha asociado al cuentadante con numero de documento ${number}` , confirmButtonText: 'Ok'})
          .then((result) => {
			      if (result.isConfirmed) {
              window.location.reload()
            }
          })
        }
      })
      .catch(ex => {
        if (ex.response.data.error === 'USER_NOT_EXIST') {
          Swal.fire({title: 'Error!',text: 'El cuentadante no existe.',icon: 'error',confirmButtonText: 'Ok'})
        }
      })
    }
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex items-center justify-center z-10">
      <div className="h-[25rem] md:w-[60rem] w-[90%] bg-[#ebecef] flex flex-col justify-evenly items-center rounded-[1rem]">
        <div className='flex justify-end md:w-[55rem] w-[90%]'>
        <XmarkCircle className='cursor-pointer' size={22} color={'#ff2825'} onClick={close}/>
        </div>
        <div className="md:w-[50rem] w-[90%] flex flex-col gap-[1rem]">
          <div className="flex gap-[0.5rem] items-center">
          <TriangleExclamation size={50} color={'#ffbf00'} />
          <p>Asociar bienes a:</p>
          </div>
          <div className="flex justify-between items-center">
            <div className="flex gap-[1.5rem]">
              <button className="bg-[#17C700] w-[8rem] h-[2rem] text-white rounded hover:bg-[#ccc] hover:font-semibold" value={'Ambiente'} onClick={(e)=>{changeInput(e.target.value)}}>Ambiente</button>
              <button className="bg-[#17C700] w-[8rem] h-[2rem] text-white rounded hover:bg-[#ccc] hover:font-semibold" value={'Cuentadante'} onClick={(e)=>{changeInput(e.target.value)}} >Cuentadante</button>
            </div>
            <div>
              {option === 1 &&
                <div className="relative">
                  <input type="number" className='h-[2rem] rounded pl-[2.5rem]' placeholder="Número de ambiente" onChange={(e)=>setNumber(e.target.value)}/> 
                  <MagnifyingGlass className='absolute left-[0.75rem] top-[50%] translate-y-[-50%]' size={18} color={'#515151'}/>
                </div>
              }
              {option === 2 &&
                <div className="relative">
                  <input type="number" className='h-[2rem] rounded pl-[2.5rem]' placeholder="Número de documento" onChange={(e)=>setNumber(e.target.value)}/> 
                  <MagnifyingGlass className='absolute left-[0.75rem] top-[50%] translate-y-[-50%]' size={18} color={'#515151'}/>
                </div>
              }
            </div>
          </div>
        </div>
        <div className="bg-[#ccc] md:w-[50rem] w-[90%] h-[10rem] rounded flex items-center gap-[1rem] flex-wrap p-8 overflow-auto">
          {
          labels.map((element) => 
            <p className="font-semibold bg-[#dcdcdc] flex justify-evenly items-center rounded pl-2">{element} <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x cursor-pointer" onClick={deleteLabels} width="18" height="18" viewBox="0 0 24 24" stroke="#ff2825" fill="none"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><line x1="18" y1="6" x2="6" y2="18" /><line x1="6" y1="6" x2="18" y2="18" /></svg></p>
          )}
        </div>
        <div>
          <button className='bg-[#17C700] w-[10rem] h-[2.5rem] text-white rounded hover:bg-[#ccc] hover:font-semibold' onClick={asociateGoods}>Asociar</button>
        </div>
      </div>
    </div>
  )
}
