/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import Swal from 'sweetalert2';
import React, {useContext, useEffect, useState} from 'react';
import { Header } from '../../Components/Layout/Header/Header';
import { FooterHome } from '../../Components/UI/FooterHome/FooterHome';
import { Dashboard } from '../../Components/Layout/Dashboard/Dashboard';
import { userContext } from '../../Components/UserProvider/UserProvider';
import { GoodsInventory } from '../../Components/UI/GoodsInventory/GoodsInventory';
import { HeaderDashboard } from '../../Components/UI/HeaderDashboard/HeaderDashboard';
import { url } from '../../Components/ApiRoutes';
import { ModalAssociating } from '../../Components/Layout/ModalAssociating/ModalAssociating';
import { ChangeButtonPrincipal } from '../../Components/UI/ChangeButtonPrincipal/ChangeButtonPrincipal';
import { ChangeButtonSecond } from '../../Components/UI/ChangeButtonSecond/ChangeButtonSecond';
import { GoodAssociatingInventory } from '../../Components/UI/GoodAssociatingInventory/GoodAssociatingInventory';
import { UpdateGood } from '../../Components/Layout/UpdateGood/UpdateGood';
import {MagnifyingGlass} from "@vectopus/atlas-icons-react";


export const Inventory = () => {

  const {user} = useContext(userContext);
  const [data, setData] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [openModal,setOpenModal] = useState(false);
  const [optionData,setOptionData] = useState('1');
  const [updateData, setUpdateData] = useState([])
  const [listaGoods, setListGoods] = useState([])
  const [search, setSearch] = useState('');
  const [active, setActive] = useState('');
  const [filter, setFilter] = useState('');
  const [stateUser, setStateUser] = useState('active');
  const [stateRoom, setStateRoom] = useState('');
  let listGood = []
  
  const setNumberOption = (event =>{
    setOptionData(event.target.value)
  })
  
  const getListGoods = (event) => {
    if(event.target.checked === true){
      listGood.push(event.target.value)
    }
    else if(event.target.checked === false){
      let index = listGood.indexOf(event.target.value)
      listGood.splice(index,1)
    }
  }  
  const setListoToState = () => {
    if(listGood.length === 0){
      Swal.fire({title: '¡Error!', text: 'No se han seleccionado bienes para asociar.', icon:'error', confirmButtonText: 'Ok'})
    }
    else {
      setListGoods(listGood)
      setOpenModal(true);
    } 
  }
  
  const config = {
    headers: { Authorization: `Bearer ${user.data.token}` }
  };

  const getGoods = () => {
    axios.get(url.AllGoods,config)
    .then(response => {
      setData(response.data.getGoods)
    })
    .catch(ex=>{
      console.log(ex.response);
    })
  }

  useEffect(()=>{
    getGoods()
	},[])

  useEffect(()=>{
    listaGoods.map((element) => 
    listGood.push(element)
    )
	},[openModal === false])

  useEffect(()=>{
    getGoods()
	},[search === ''])

  const manageSearch = () => {
    if (filter === ''){
      Swal.fire({title: '¡Error!', text: 'Debe de seleccionar un filtro', icon:'error', confirmButtonText: 'Ok'})
    } else if(filter === 'Ambiente'){
      axios.get(url.RoomsInventory+search, config)
      .then(response => {
        setData(response.data.listGoods);
      })
      .catch(ex=>{
        if(ex.response.data.error === 'ROOM_NOT_EXIST'){
          Swal.fire({title: '¡Error!', text: 'El número de ambiente no existe', icon:'error', confirmButtonText: 'Ok'})
        }
      })
    } else if (filter === 'Cuentadante'){
      axios.get(url.GetUsers+search)
      .then(response => {
        setData(response.data.users.goods);
      })
      .catch( ex => {
        if(ex.response.data.error === 'USER_NOT_EXIST'){
          Swal.fire({title: '¡Error!', text: 'El cuentadante no existe', icon:'error', confirmButtonText: 'Ok'})
        }
      })
    }
  }

  const manageSearchInventory = () => {
    if(search === ''){
      setDataSearch('');
      Swal.fire({title: '¡Error!', text: 'Debe ingresar un valor', icon:'error', confirmButtonText: 'Ok'})
    }
    if(stateUser === 'active'){
      if(search !== ''){
        axios.post(url.FilterGoods+1 ,{
          "document":search
        }, config)
        .then(response => {
          setDataSearch(response.data[0]);
        })
        .catch(ex => {
          console.log(ex.response)
          if(ex.response.data.error === 'USER_NOT_EXIST'){
            Swal.fire({title: '¡Error!', text: 'El número de documento no existe', icon:'error', confirmButtonText: 'Ok'})
          }
        })
      }
    }
    if(stateRoom === 'active'){
      if(search !== 'active'){
        axios.get(url.RoomsGoods+search, config)
        .then(response => {
          setDataSearch(response.data.roomData[0].goods);
        })
        .catch(ex => {
          if(ex.response.data.error === 'ROOM_NOT_EXIST'){
            Swal.fire({title: '¡Error!', text: 'El número de ambiente no existe', icon:'error', confirmButtonText: 'Ok'})
          }
        })
      }
    }
  }

  const keyUp=(event)=>{
    if (event.keyCode===13) {
      setActive('');
      manageSearch();
    }
  }

  const keyUpInventory=(event)=>{
    if (event.keyCode===13) {
      manageSearchInventory();
    }
  }

  const headerInformation = {
    names: 'Gestionar',
    lastNames: 'Inventario',
		links: [
      {
        url:'/',
        name: 'Inicio'
      },
			{
				url: '',
				name: 'Asociar inventario a un Ambiente',
			},			{
				url: '',
				name: 'Toma física',
			},
		]
  }

  const sizeColumn = {
    column1 : "5rem",
    column2 : "6rem",
    column3 : "7rem",
    column4 : "5rem",
    column5 : "2rem",
    column6 : "7rem",
    column7 : "4rem",
  }

  const headerDashboardInformation = {
    columnText1 : "Placa",
    columnText2 : "Elemento",
    columnText3 : "Descripción",
    columnText4 : "Atributos",
    columnText5 : "Valor",
    columnText6 : "Ubicación",
    columnText7 : "Acciones",
    columnWidth1 : sizeColumn.column1,
    columnWidth2 : sizeColumn.column2,
    columnWidth3 : sizeColumn.column3,
    columnWidth4 : sizeColumn.column4,
    columnWidth5 : sizeColumn.column5,
    columnWidth6 : sizeColumn.column6,
    columnWidth7 : sizeColumn.column7,
  }
  
  const associatingGoods = () => {
    setListoToState()
  }

  const changeButton = {
    buttonWidth1: "7rem",
    buttonWidth2: "7rem",
    buttonText1: "Cuentadante",
    buttonText2: "Ambiente",
  }

  const setDataToUpdate = ((e,index)=> {
    setUpdateData(dataSearch[index])
    setOptionData('3');
  })
  
  const setFocus = e => {
    e.target && e.target.focus();
};

  return (
    <div>
      <div className='sm:w-[100%]'>
        <Header data={headerInformation} setNumber={setNumberOption}/>
      </div>
      <div className='sm:w-[100%] w-full flex justify-center items-center flex-col'>
        <div className={`w-full h-[60rem] flex flex-col justify-evenly items-center`}>
          {optionData === '1' &&
            <>
              <div className='relative w-[84%] flex justify-center items-center'>
                <input type="search" className={`${filter===''? "pl-[2.5rem]" : "pl-[11rem]"} h-[3rem] w-full pl-[2.5rem] border-2 border-[#e7ebda] rounded outline-none bg-[#f4f4f4] box-border`} placeholder="Buscar bienes" onClick={()=>{setActive('active')}} onChange={(e)=>{setSearch(e.target.value)}} onBlur={(e) => setFocus(e)} onKeyUp={(e) => keyUp(e)}/>
                <MagnifyingGlass className='absolute left-[0.75rem] top-[50%] translate-y-[-50%]' size={18} color={'#515151'}/>
                <p className="absolute top-[50%] left-[2.8rem] translate-y-[-50%] font-semibold bg-[#dcdcdc] flex justify-evenly items-center w-[8rem] rounded">{filter} <svg xmlns="http://www.w3.org/2000/svg" className={`${filter===''? "hidden": "icon icon-tabler icon-tabler-x cursor-pointer" }`} onClick={()=>{setFilter('')}} width="18" height="18" viewBox="0 0 24 24" stroke="#ff2825" fill="none"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><line x1="18" y1="6" x2="6" y2="18" /><line x1="6" y1="6" x2="18" y2="18" /></svg></p>
              </div>
              <div className={`${active==='active' ? "flex w-full h-[3rem] items-center justify-evenly" : "hidden"}`}>
                <p className='font-semibold bg-[#dcdcdc] rounded w-[8rem] text-center p-[0.3rem] cursor-pointer' id='Ambiente' onClick={(e)=>{setFilter(e.target.id); setActive('')}}>Ambiente</p>
                <p className='font-semibold bg-[#dcdcdc] rounded w-[8rem] text-center p-[0.3rem] cursor-pointer' id='Cuentadante' onClick={(e)=>{setFilter(e.target.id); setActive('')}}>Cuentadante</p>
              </div>
              <div className='w-full flex items-center gap-[1rem] flex-col'>
                <HeaderDashboard headerDashboardInformation={headerDashboardInformation}/>
                {
                <Dashboard dashboardInformation={
                  data.map((good, index) => 
                  <>
                    <GoodsInventory goodInformation={good} key={index} id={index} goodList={getListGoods}/>
                  </>
                )}/>
                }
                <div className="w-[80%] flex justify-end">
                  <button className='bg-[#17C700] w-[10rem] h-[2.5rem] text-white rounded hover:bg-[#ccc] hover:font-semibold' onClick={associatingGoods}>Asociar</button>
                </div>
                <div>
                  <ModalAssociating labels={updateData} onClose={setOpenModal} visible={openModal}/>
                </div>
              </div>
            </>
          }
          {optionData === '2' && 
            <>
              <div className={`flex justify-evenly w-full flex-col items-center gap-[1rem]`}>
                <div className="flex w-[100%] justify-evenly items-center">
                  <ChangeButtonPrincipal size={changeButton.buttonWidth1} text={changeButton.buttonText1} statePrincipal={stateUser} setStatePrincipal={setStateUser} setStateSecond={setStateRoom}/>
                  <ChangeButtonSecond size={changeButton.buttonWidth2} text={changeButton.buttonText2}  stateSecond={stateRoom} setStateSecond={setStateRoom} setStatePrincipal={setStateUser}/>
                </div>
                {
                  stateUser === 'active' && 
                  <>
                    <div className='relative w-[84%] flex justify-center items-center'>
                      <input type="search" className='h-[3rem] w-full pl-[2.5rem] border-2 border-[#e7ebda] rounded outline-none bg-[#f4f4f4] box-border' placeholder='Número de documento' onKeyUp={(e) => keyUpInventory(e)} onChange={(e) => {setSearch(e.target.value); setDataSearch([])}}/>
                      <MagnifyingGlass className='absolute left-[0.75rem] top-[50%] translate-y-[-50%]' size={18} color={'#515151'}/>
                    </div>
                  </>
                }
                {
                  stateRoom === 'active' && 
                  <>
                    <div className='relative w-[84%] flex justify-center items-center'>
                      <input type="search" className='h-[3rem] w-full pl-[2.5rem] border-2 border-[#e7ebda] rounded outline-none bg-[#f4f4f4] box-border' placeholder='Número de ambiente' onKeyUp={(e) => keyUpInventory(e)} onChange={(e) => {setSearch(e.target.value); setDataSearch([])}}/>
                      <MagnifyingGlass className='absolute left-[0.75rem] top-[50%] translate-y-[-50%]' size={18} color={'#515151'}/>
                    </div>
                  </>       
                } 
                <div className='w-full flex items-center gap-[1rem] flex-col'>
                <HeaderDashboard headerDashboardInformation={headerDashboardInformation}/>
                {
                  <Dashboard dashboardInformation={
                    dataSearch.length === 0 || search === '' ? <p className='font-semibold text-lg'>No se ha generado una búsqueda</p> : dataSearch.map((item, index) => 
                    <>
                      <GoodAssociatingInventory goodInformation={item} key={item.labels} index={index} updateData={setDataToUpdate}/>
                    </>
                  )}/>
                }
              </div>
              </div>
            </>
          }
          {optionData === '3' &&
            <UpdateGood updateData={updateData} />
          }
          <div>
            <ModalAssociating labels={listaGoods} onClose={setOpenModal} visible={openModal}/>
          </div>
        </div>
        <div className='w-full'>
          <FooterHome/>
        </div>
      </div>
    </div>
  )
}