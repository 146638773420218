/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { decodeToken } from 'react-jwt';
import { useNavigate } from 'react-router-dom';
import { styles } from '../../Components/styles';
import { Header } from '../../Components/Layout/Header/Header';
import React, { useState, useEffect, useContext } from 'react';
import { url } from '../../Components/ApiRoutes';
import { Dashboard } from '../../Components/Layout/Dashboard/Dashboard';
import { userContext } from '../../Components/UserProvider/UserProvider';
import { RegisterUser } from '../../Components/Layout/RegisterUser/RegisterUser'
import { UserInformation } from '../../Components/UI/UserInformation/UserInformation';
import { HeaderDashboard } from '../../Components/UI/HeaderDashboard/HeaderDashboard';
import { ChangeInformationDashboard } from '../../Components/Layout/ChangeInformationDashboard/ChangeInforationDashboard';
import { FooterHome } from '../../Components/UI/FooterHome/FooterHome';


export const Users = () => {

  const navigate = useNavigate()
  const [data,setData] = useState([]);
  const [adminData,setAdminData] = useState([])
  const [stateUser, setStateUser] = useState('active');
  const [userProfileData,setUserProfileData]= useState([])
  const [stateAdministrator, setStateAdministrator] = useState('');

  const [optionData,setOptionData]=useState('1')
  const {user} = useContext(userContext);
  const tokenData = decodeToken(user.data.token)

  const config = {
    headers: { Authorization: `Bearer ${user.data.token}` }
  };

  const getAllusers = () => {
    axios.get(url.AllUsers+tokenData.userName,config)
    .then(response => {
      setData(response.data.users)
    })
    .catch(ex=>{
      console.log('ERROR'+ex);
    })
  };

  const getAllAdmins = () => {
    axios.get(url.AllAdmin+tokenData.userName,config)
    .then(response =>{
      setAdminData(response.data.users)
    })
    .catch(ex=>{
      console.log('ERROR'+ex)
    })
  }

  const getIndexUser = (e) =>{
    setUserProfileData(data[e.target.id])
    localStorage.setItem('dataUser', JSON.stringify(data[e.target.id]))
    navigate('/UserProfile')
    window.location.reload()
  }

  useEffect(()=>{
		getAllusers()
    getAllAdmins()
	},[''])

  const setNumberOption = (event =>{
    setOptionData(event.target.value)
  })

  const sizeColumn = {
    column1 : "3rem",
    column2 : "8rem",
    column3 : "8rem",
    column4 : "10rem",
    column5 : "9rem",
    column6 : "7rem",
    column7 : "5rem",
  }

  const buttonInformation = {
    buttonWidth1 : "7rem",
    buttonWidth2 : "9rem",
    buttonText1 : "Usuarios",
    buttonText2 : "Administradores",
    stateUser : stateUser,
    setStateUser : setStateUser,
    stateAdministrator : stateAdministrator,
    setStateAdministrator : setStateAdministrator
  }

  const headerDashboardInformation = {
    columnText1 : "Foto",
    columnText2 : "Nombre",
    columnText3 : "Correo",
    columnText4 : "Rol",
    columnText5 : "Centro de formación",
    columnText6 : "Estado",
    columnText7 : "Acciones",
    columnWidth1 : sizeColumn.column1,
    columnWidth2 : sizeColumn.column2,
    columnWidth3 : sizeColumn.column3,
    columnWidth4 : sizeColumn.column4,
    columnWidth5 : sizeColumn.column5,
    columnWidth6 : sizeColumn.column6,
    columnWidth7 : sizeColumn.column7,
  }

  const headerInformation = {
    names: 'Gestionar',
    lastNames: 'Usuarios',
		links: [
      {
        url:'/',
        name: 'Inicio'
      },
			{
				url: '',
				name: 'Lista de usuarios',
			},
			{
				url: '',
				name: 'Registrar usuarios',
			},
		]
  }


  return (
    <div className=''> 
      <div className='sm:w-[100%]'>
        <Header data={headerInformation} setNumber={setNumberOption}/>
      </div>
      <div className='sm:w-[100%] w-full flex justify-center items-center flex-col'>
        <div className={`${styles.mainContainer}`}>
          {optionData === '1' && 
          <>
            <ChangeInformationDashboard data={stateUser==='active'? data : adminData} buttonInformation={buttonInformation}/>
            <HeaderDashboard headerDashboardInformation={headerDashboardInformation}/>
            {
              stateAdministrator === 'active' &&
              <Dashboard dashboardInformation={
                adminData?.map((user, index) =>
                  <UserInformation userInformation={user} sizeColumn={sizeColumn} getIndexUser={getIndexUser} key={index} id={index}/>  
                  )}/>
            }
            {stateUser === 'active' && 
              <Dashboard dashboardInformation={
                data?.map((user, index) =>
                  <UserInformation userInformation={user} sizeColumn={sizeColumn} getIndexUser={getIndexUser} key={index} id={index}/>  
                  )}/>
            }
          </>
          }
          {optionData === '2' && 
            <RegisterUser/>
          }
        </div>
        <div className='w-full'>
          <FooterHome/>
        </div>
      </div>
    </div>
  )
}