/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios'
import {React, useContext ,useEffect,useState} from 'react'
import { isExpired, decodeToken } from "react-jwt";
import { SearchCard } from '../../Layout/SearchCard/SearchCard'
import { CardHome } from '../../UI/CardHome/CardHome'
import { FooterHome } from '../../UI/FooterHome/FooterHome'
import { userContext } from '../../UserProvider/UserProvider'
import { Header } from '../../Layout/Header/Header'
import { headerHomeInfo, headerHomeInfoAdmin, headerHomeInfoInstructor,headerHomeInfoConcierge  } from '../../textInformation';
import {DimensionalView, Concierge , GroupTeamCollective, ManagerMeeting } from "@vectopus/atlas-icons-react";
import { url } from '../../ApiRoutes';
import { HelpCard } from '../../UI/HelpCard/HelpCard';



export const Home = () => {

	// const [userActive, setUserActive] = useState('default')
  const [userSession, setUserSession] = useState(0)
  const [token, setToken]=useState('')
  const isMyTokenExpired = isExpired(token);
	const {user} = useContext(userContext)
	const[Ambientes,setAmbientes]= useState([])
	const[Bienes,setBienes]= useState([])
	const[Instructores,setInstructores]= useState([])
	const[Conserjes,setConserjes]= useState([])
	const[headerOptions,setHeaderOptions]= useState(0)

	const setUserToken = () => {
		if (user !== null) {
      setToken(user.data.token);
      setUserSession(1)
      // setUserActive('user-active')
    }
    else{
      setUserSession(0)
    }
	}

	// const closeSesion = () => {
  //   if(userActive === 'user-active' & userSession === 1 ){
  //     localStorage.removeItem('userInfo')
  //     localStorage.setItem('userInfo',null)
  //     window.location.reload()
  //   }else{
  //     setUserActive('default')
  //     setUserSession(0)
  //   }
  // }
	
	useEffect(()=>{
		if(isMyTokenExpired === true){
			
    }
	},[isMyTokenExpired])

	useEffect(()=>{
	axios.get(url.TotalInstructors)
			.then(response=> setInstructores(response.data.totalInstructors))
			.catch(error =>console.log(error))
	axios.get(url.TotalCaretaker)
			.then(response=> setConserjes(response.data.totalCaretaker))
			.catch(error =>console.log(error))
	axios.get(url.TotalGoods)
			.then(response=> setBienes(response.data.totalGoods))
			.catch(error =>console.log(error))
	axios.get(url.TotalRooms)
			.then(response=> setAmbientes(response.data.totalRooms))
			.catch(error =>console.log(error))
	},[])

	const changeHeaderInfo = (()=>{
		if(user != null){
			const tokenData = decodeToken(user.data.token)
				if (tokenData.role === 'R01') {
					setHeaderOptions(1)
				}
				else if(tokenData.role === 'R02'){
					setHeaderOptions(2)
				}
				else if(tokenData.role === 'R03'){
					setHeaderOptions(3)
				}
		}
	})

	useEffect(()=>{
		setUserToken()
		changeHeaderInfo()
	},[ ])
	

	return (
	<div className='static flex flex-row-reverse'>
		<div className='w-[5rem] h-[25rem] flex justify-center mt-4 bg-transparent fixed z-20 '>
				<HelpCard/>
		</div>
		<div className='sm:w-[100%] w-[100%] h-[100%] flex justify-center gap-[8rem] flex-col '>
			<div className= 'sm:w-full'>
			{headerOptions === 0 &&
					<Header data={headerHomeInfo} userSession={userSession}  />
				}
				{headerOptions === 1 &&
					<Header data={headerHomeInfoAdmin} userSession={userSession}  />
				}
				{headerOptions === 2 &&
					<Header data={headerHomeInfoInstructor} userSession={userSession}  />
				}
				{headerOptions === 3 &&
					<Header data={headerHomeInfoConcierge} userSession={userSession}  />
				}
			</div>
			<div className='w-[100%] flex flex-wrap justify-center gap-[4rem]'>
				<CardHome title={"Bienes"} icon={<DimensionalView size={28} color={'#ffffff'}/>} number={Bienes} container_card='52%'/>
				<CardHome title={"Conserjes"} icon={<Concierge size={28} color={'#ffffff'}/>} number={Conserjes} container_card='52%'/>
				<CardHome title={"Instructores"} icon={<GroupTeamCollective size={28} color={'#ffffff'}/>} number={Instructores} container_card='41%'/>
				<CardHome title={"Ambientes"} icon={<ManagerMeeting size={28} color={'#ffffff'}/>} number={Ambientes} container_card='41%'/>
			</div>
			<div className='w-full flex justify-center'>
				<div className='lg:w-[80rem]  w-[80%] xl:h-[35rem] lg:h-[35rem]  h-[40rem] flex justify-center  items-center rounded-md bg-[#f2f2f2] shadow-xl'>
					<SearchCard />
				</div>
			</div>
			<div className='w-full'>
				<FooterHome/>
			</div>
		</div>
	</div>
	)
}

