import React from 'react'
import {PencilEdit} from "@vectopus/atlas-icons-react";

export const RoomsInformation = ({roomsInformation, roomslength, updateData, index}) => {
  return (
    <div className={`h-[2.5rem] bg-[#ececec] rounded w-[97%] flex items-center justify-around gap-[1rem]`}>
      <p className={`w-[7rem] text-center `}>{roomsInformation.number} </p>
      <p className={`w-[6rem] text-center  `}>{roomsInformation.type}</p>
      <p className={`w-[7rem] text-center truncate`}>{roomsInformation.roomName}</p>
      <p className={`w-[7rem] text-center truncate`}>{roomsInformation.description}</p>
      <p className={`w-[6rem] text-center `}>{roomsInformation.multiRooms}</p>
      <p className={`w-[6rem] text-center  `}>{roomslength.length}</p>
      <button className={`w-[2rem] flex justify-center items-center`} onClick={(e => updateData(index))}><PencilEdit size={20}/></button>
    </div>
  )
}