import React from 'react'
import { styles } from '../../styles';

export const RoomsInformationConcierge = ({roomsInformation, sizeColumn,roomslength, updateData, index}) => {
  return (
    <div className={`h-auto bg-[#ececec] rounded w-[97%] flex items-center justify-around gap-[1rem]`}>
    <p className={`w-[7rem] text-center `}>{roomsInformation.number} </p>
    <p className={`w-[6rem] text-center  `}>{roomsInformation.type}</p>
    <p className={`w-[7rem] text-center truncate`}>{roomsInformation.roomName}</p>
    <p className={`w-[7rem] text-center truncate`}>{roomsInformation.description}</p>
    <p className={`w-[6rem] text-center `}>{roomsInformation.multiRooms}</p>
    <p className={`w-[6rem] text-center  `}>{roomslength.length}</p>
  </div>
  )
}
