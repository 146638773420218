/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import  LogoInventario from '../../assets/LogoInventario.png'
import '../Header/Header.css'
import { HeaderUser } from '../../UI/HeaderUser/HeaderUser'
import { decodeToken } from 'react-jwt'
import { useContext } from 'react'
import { userContext } from '../../UserProvider/UserProvider'


export const Header = ({data, setNumber, displaySize}) => {

  const [toggle, setToggle] = useState(false);
	const [userActive, setUserActive] = useState('default')
  const [userSession, setUserSession] = useState(0)
  const {user} = useContext(userContext)
  const [token, setToken]=useState('')
  const tokenData = decodeToken(token)
  
  const setUserToken = () => {
		if (user !== null) {
      setToken(user.data.token);
      setUserSession(1)
      setUserActive('user-active')
    }
    else{
      setUserSession(0)
    }
	}

  useEffect(()=>{
		setUserToken()
	},[])

  return (
    <header className='w-full h-[6rem] flex justify-center items-center'>
      <div className='md:flex hidden w-[90%] h-full border-b-2 justify-evenly items-center'> 
        <div className='w-[15%] '>
          <Link to='/'>
            <img src={LogoInventario} alt="InventarioLogo" className='w-[6rem] h-[5rem] cursor-pointer' />
          </Link>
        </div>
        <div className='w-[60%] flex justify-evenly'>
          {data.links.map((element, index) => (
            <>
              <Link to={`${element.url}`} className={'text-[1.3rem] hover:text-black  btnNavLink'} key={index} ><button onClick={(e)=>setNumber(e)} value={index} key={index}  >{element.name}</button></Link>
            </>
          ))}
        </div>
        <div className='w-[15%] flex justify-end'>
					{userSession === 1 &&
					<>
						<HeaderUser userActive={userActive} userSession={userSession} img={tokenData.photo} userName={tokenData.names}/>
					</>
					}
          {userSession === 0 && 
            <Link to='/login' className='text-[1.3rem] hover:text-black btnNavLink'>Iniciar Seccion</Link>
          }
				</div>
      </div>
      <div className='md:hidden w-[90%] h-full border-b-2 flex justify-between items-center z-10'>
        <div className='md:hidden w-[8rem]'>
        {toggle === false  ?
        <>
          <div className='switch' >
            <input type="checkbox"  onClick={() => setToggle(!toggle)}/>
            <div>
              <span className="line-1"></span>
              <span className="line-2"></span>
              <span className="line-3"></span>
            </div>
          </div>
        </> :
        <>
        <div className='switch' >
          <input type="checkbox"  onClick={() => setToggle(!toggle)}/>
          <div>
            <span className="line-1"></span>
            <span className="line-2"></span>
            <span className="line-3"></span>
          </div>
        </div>
        </>
        }
        {toggle === true &&
          <div className="absolute left-[1.3rem] top-[4rem] w-[10rem] h-[20rem] flex justify-center items-center rounded-md bg-slate-200 z-10">
            <div className='h-[90%] flex justify-evenly flex-col '>
              {data.links.map((element, index) => (
                <>
                <Link to={`${element.url}`} className={'text-[1.3rem] hover:text-black  btnNavLink'} key={index} ><button onClick={(e)=>setNumber(e)} value={index} key={index}  >{element.name}</button></Link>
              </>
            ))}
          </div>
          </div>
        }
        </div>
        <div className='w-[15rem] flex justify-end'>
					{userSession === 1 &&
					<>
						<HeaderUser userActive={userActive} userSession={userSession} img={tokenData.photo}/>
					</>}
          {userSession === 0 && 
            <Link to='/login' className='hover:text-black btnNavLink' >Iniciar Seccion</Link>
          }
				</div>
      </div>
    </header>
  )
}
