/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios'
import React, {useContext, useEffect, useState} from 'react'
import Swal from 'sweetalert2'
import { url } from '../../Components/ApiRoutes'
import { Dashboard } from '../../Components/Layout/Dashboard/Dashboard'
import { Header } from '../../Components/Layout/Header/Header'
import { FooterHome } from '../../Components/UI/FooterHome/FooterHome'
import { GoodInformation } from '../../Components/UI/GoodInformation/GoodInformation'
import { HeaderDashboard } from '../../Components/UI/HeaderDashboard/HeaderDashboard'
import { userContext } from '../../Components/UserProvider/UserProvider'
import {MagnifyingGlass} from "@vectopus/atlas-icons-react";


export const InventoryConcierge = () => {
  const {user} = useContext(userContext);
  const [data, setData] = useState([]);
  const [openModal,setOpenModal]=useState(false);
  const [optionData,setOptionData]=useState('0');
  const [listaGoods, setListGoods] =useState([])
  const [search, setSearch] = useState(' ');
  const [active, setActive] = useState('');
  const [filter, setFilter] = useState('');
  let listGood = []
  
  const setNumberOption = (event =>{
    setOptionData(event.target.value)
  })
  
  const getListGoods = (event) => {
    if(event.target.checked === true){
      listGood.push(event.target.value)
    }
    else if(event.target.checked === false){
      let index = listGood.indexOf(event.target.value)
      listGood.splice(index,1)
    }
  }  
  const setListoToState = () => {
    setListGoods(listGood)
  }
  
  const config = {
    headers: { Authorization: `Bearer ${user.data.token}` }
  };

  const getGoods = () => {
    axios.get(url.AllGoods,config)
    .then(response => {
      setData(response.data.getGoods)
    })
    .catch(ex=>{
      console.log(ex.response);
    })
  }

  useEffect(()=>{
    getGoods()
	},[])

  useEffect(()=>{
    listaGoods.map((element) => 
    listGood.push(element)
    )
	},[openModal === false])

  useEffect(()=>{
    getGoods()
	},[search === ''])

  const manageSearch = () => {
    if (filter === ''){
      Swal.fire({title: 'Error!', text: 'Debe de seleccionar un filtro', icon:'error', confirmButtonText: 'Ok'})
    } else if(filter === 'Ambiente'){
      axios.get(url.RoomsInventory+search, config)
      .then(response => {
        setData(response.data.listGoods);
      })
      .catch(ex=>{
        if(ex.response.data.error === 'ROOM_NOT_EXIST'){
          Swal.fire({title: 'Error!', text: 'El número de ambiente no existe', icon:'error', confirmButtonText: 'Ok'})
        }
      })
    } else if (filter === 'Cuentadante'){
      axios.get(url.GetUsers+search)
      .then(response => {
        setData(response.data.users.goods);
      })
      .catch( ex => {
        if(ex.response.data.error === 'USER_NOT_EXIST'){
          Swal.fire({title: 'Error!', text: 'El cuentadante no existe', icon:'error', confirmButtonText: 'Ok'})
        }
      })
    }
  }

  const keyUp=(event)=>{
    if (event.keyCode===13) {
      setActive('');
      manageSearch();
    }
  }


  const headerInformation = {
    names: 'Gestionar',
    lastNames: 'Inventario',
		links: [
      {
        url:'/',
        name: 'Inicio'
      },
			{
				url: '',
				name: 'Asociar inventario a un Ambiente',
			},
		]
  }

  const sizeColumn = {
    column1 : "9rem",
    column2 : "4rem",
    column3 : "9rem",
    column4 : "6rem",
    column5 : "6rem",
    column6 : "2rem",
  }


  const headerDashboardInformation = {
    columnText1 : "Placa",
    columnText2 : "Elemento",
    columnText3 : "Descripción",
    columnText4 : "Atributos",
    columnText5 : "Valor",
    columnText6 : "Ubicación",
    columnWidth1 : sizeColumn.column1,
    columnWidth2 : sizeColumn.column2,
    columnWidth3 : sizeColumn.column3,
    columnWidth4 : sizeColumn.column4,
    columnWidth5 : sizeColumn.column5,
    columnWidth6 : sizeColumn.column6,
  }

  const associatingGoods = () => {
    setOpenModal(true);
    setListoToState()
  }

  return (
    <div>
      <div className='w-[100%]'>
        <Header data={headerInformation} setNumber={setNumberOption}/>
      </div>
      <div className='sm:w-[100%] w-[100 flex justify-center items-center flex-col'>
        <div className={`w-full h-[56rem] flex flex-col justify-evenly items-center`}>
          <div className='w-[84%]'>
            <div className='relative w-full'>
              <input type="search" className={`${filter===''? "pl-[2.5rem]" : "pl-[11rem]"} h-[3rem] w-full pl-[2.5rem] border-2 border-[#e7ebda] rounded outline-none bg-[#f4f4f4] box-border`} placeholder="Buscar bienes" onClick={()=>{setActive('active')}} onChange={(e)=>{setSearch(e.target.value)}} onKeyUp={(e) => keyUp(e)}/>
              <MagnifyingGlass className='absolute left-[0.75rem] top-[50%] translate-y-[-50%]' size={18} color={'#515151'}/>
              <p className="absolute top-[50%] left-[2.8rem] translate-y-[-50%] font-semibold bg-[#dcdcdc] flex justify-evenly items-center w-[8rem] rounded">{filter} <svg xmlns="http://www.w3.org/2000/svg" className={`${filter===''? "hidden": "icon icon-tabler icon-tabler-x cursor-pointer" }`} onClick={()=>{setFilter('')}} width="18" height="18" viewBox="0 0 24 24" stroke="#ff2825" fill="none"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><line x1="18" y1="6" x2="6" y2="18" /><line x1="6" y1="6" x2="18" y2="18" /></svg></p>
            </div>
            <div className={`${active==='active' ? "flex w-full h-[3rem] items-center justify-evenly" : "hidden"}`}>
              <p className='font-semibold bg-[#dcdcdc] rounded w-[8rem] text-center p-[0.3rem] cursor-pointer' id='Ambiente' onClick={(e)=>{setFilter(e.target.id); setActive('')}}>Ambiente</p>
              <p className='font-semibold bg-[#dcdcdc] rounded w-[8rem] text-center p-[0.3rem] cursor-pointer' id='Cuentadante' onClick={(e)=>{setFilter(e.target.id); setActive('')}}>Cuentadante</p>
            </div>
          </div>
          <HeaderDashboard headerDashboardInformation={headerDashboardInformation}/>
          {
          <Dashboard dashboardInformation={
            data.map((good, index) => 
            <>
              <GoodInformation goodInformation={good} key={index} id={index} goodList={getListGoods}/>
            </>
          )}/>
          }
        </div>
        <div className='w-full'>
          <FooterHome/>
        </div>
      </div>
    </div>
  )
}