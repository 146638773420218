import React from 'react';
import { styles } from '../../styles';

export const ButtonUploadFile = ({captureExcel}) => {

  return (
    <div className='h-[8rem] flex justify-evenly items-center flex-col'>
      <div className='w-[15rem] flex justify-evenly items-center'>
        <hr className={`${styles.line}`}/>
        <p className='text-stone-500'>o</p>
        <hr className={`${styles.line}`}/>
      </div>
      <input type="file" className={`${styles.fileInput}`} onChange={(e => captureExcel(e) )}/>
    </div>
  )
}