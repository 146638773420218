import axios from 'axios';
import React,{useContext, useState} from 'react'
import Swal from 'sweetalert2';
import { url } from '../../ApiRoutes';
import { userContext } from '../../UserProvider/UserProvider'

export const UpdateUserData = ({data, nameContext}) => {

const {user} = useContext(userContext)

const [names,setNames]=useState(data.names)
const [lastNames,setLastNames]=useState(data.lastNames)
const [homeAddress,setHomeAddress]=useState(data.homeAddress)
const [phoneNumber,setPhoneNumber]=useState(data.phoneNumber)
const [cellPhoneNumber, setCellPhoneNumber]=useState(data.cellPhoneNumber)
const [regional, setRegional]=useState(data.regional)
const [trainingCenter, setTrainingCenter]=useState(data.trainingCenter)

const config = {
	headers: { Authorization: `Bearer ${user.data.token}` }
};

const bodyParameters = {
	names:names,
	lastNames:lastNames,
	homeAddress:homeAddress,
	phoneNumber:phoneNumber,
	cellPhoneNumber:cellPhoneNumber,
	regional:regional,
	trainingCenter:trainingCenter
};

const phoneNumberState = (e) => {
	const value = e.target.value.replace(/\D/g, "");
	setPhoneNumber(value);
}

const cellPhoneNumberState = (e) => {
	const value = e.target.value.replace(/\D/g, "");
	setCellPhoneNumber(value);
}


const updateUserData = (()=>{
	Swal.fire({
		title: '¿Quiere guardarr los cambios?',
		showDenyButton: true,
		showCancelButton: true,
		confirmButtonText: 'Save',
		denyButtonText: `Don't save`,
	}).then((result) => {
		if (result.isConfirmed) {
			axios.put(url.UpdateUserData+data.document, bodyParameters ,config)
			.then(response => {
				console.log(response);
				Swal.fire('Saved!', '', 'success')
				window.location.reload()
			})
			.catch(ex=>{
				console.log(ex.response.data.error);
			})
		} else if (result.isDenied) {
			Swal.fire('Los datos no se actualizaron', '', 'info')
		}
	})
})


return (
		<div className='w-full h-screen flex flex-col justify-around items-center' >
			<div className='w-[70%] flex justify-around items-center'>
				<h1 className='text-[2.5rem]'>Actualizar datos</h1>
				<img src={`${data.QR}`} alt="UserQR" />
			</div>
			<div className='w-[80%] h-[60%] grid grid-cols-2 justify-between'>
				<div className='flex flex-col justify-center items-center'>
					<label className='text-[1.2rem] w-[80%]'>Nombres</label>
					<input type="text" className='w-[80%] h-[2.5rem] rounded-b-md border-solid border-green-600 border-2 pl-1' placeholder={data.names} onChange={(e)=>{setNames(e.target.value)}}/>
				</div>
				<div className='flex flex-col justify-center items-center'>
					<label className='text-[1.2rem] w-[80%]'>Apellidos</label>
					<input type="text" className='w-[80%] h-[2.5rem] rounded-b-md border-solid border-green-600 border-2 pl-1' placeholder={data.lastNames} onChange={(e)=>{setLastNames(e.target.value)}} />
				</div>
				<div className='flex flex-col justify-center items-center'>
					<label className='text-[1.2rem] w-[80%]'>Dirección</label>
					<input type="text" className='w-[80%] h-[2.5rem] rounded-b-md border-solid border-green-600 border-2 pl-1' placeholder={data.homeAddress} onChange={(e)=>{setHomeAddress(e.target.value)}}/>
				</div>
				<div className='flex flex-col justify-center items-center'>
					<label className='text-[1.2rem] w-[80%]'>Número de teléfono</label>
					<input className='w-[80%] h-[2.5rem] rounded-b-md border-solid border-green-600 border-2 pl-1' placeholder={data.phoneNumber} onChange={(e)=>{phoneNumberState(e)}} value={phoneNumber}/>
				</div>
				<div className='flex flex-col justify-center items-center'>
					<label className='text-[1.2rem] w-[80%]'>Número de celular</label>
					<input className='w-[80%] h-[2.5rem] rounded-b-md border-solid border-green-600 border-2 pl-1' placeholder={data.cellPhoneNumber} onChange={(e)=>{cellPhoneNumberState(e)}} value={cellPhoneNumber}/>
				</div>
				<div className='flex flex-col justify-center items-center'>
					<label className='text-[1.2rem] w-[80%]'>Regional</label>
					<input type="text" className='w-[80%] h-[2.5rem] rounded-b-md border-solid border-green-600 border-2 pl-1' placeholder={data.regional} onChange={(e)=>{setRegional(e.target.value)}}/>
				</div>
				<div className='flex flex-col justify-center items-center'>
					<label className='text-[1.2rem] w-[80%]'>Centro de formación</label>
					<input type="text" className='w-[80%] h-[2.5rem] rounded-b-md border-solid border-green-600 border-2 pl-1' placeholder={data.trainingCenter} onChange={(e)=>{setTrainingCenter(e.target.value)}}/>
				</div>
        <div className='flex justify-center items-center'>
            <button className='rounded hover:text-white w-40 h-10 text-center bg-[#17C700] text-lg' onClick={updateUserData}>Actualizar</button>
        </div>
			</div>
		</div>
)
}
