import './SearchCard.css'
import axios from 'axios';
import Swal from 'sweetalert2';
import React, { useState } from 'react';
import { url } from '../../ApiRoutes';
import { ModalHome } from '../../UI/ModalHome/ModalHome';
import { UserModal } from '../../UI/UserModal/UserModal';
import {MagnifyingGlass} from "@vectopus/atlas-icons-react";

export const SearchCard = () => {

  const[openModal,setOpenModal]=useState(false)
  const[userModal,setUserModal]=useState(false)

  const [label, setLabel]=useState('')
  const [labelData,setLabelData] = useState([])

  const [document,setDocument]=useState('')
  const [userData,setUserData] = useState([])
  
  const setEventToLabel = ((event)=>{
    const value = event.target.value.replace(/\D/g, "");
    setLabel(value);
  })

  const setEventToDocument = ((event)=>{
    const value = event.target.value.replace(/\D/g, "");
    setDocument(value)
  })
  
  const getLabel = (()=>{
    if(label !=='' && label !== null && label !== undefined ){
      axios.get(url.GetLabels+label)
      .then(response	=> {
        setLabelData(response.data[0])
        setLabel('')
        setOpenModal(true)
      })
      .catch(ex=>{
        if (ex.response.data.error === 'GOOD_NOT_EXIST') {
          Swal.fire({title: '¡Error!',text: 'El bien no existe',icon: 'error',confirmButtonText: 'Ok'
          })
        }
      })
    }
    else{
      Swal.fire({title: '¡Error!',text: 'Campos vacíos',icon: 'error',confirmButtonText: 'Ok'
      })
    }
  })

  const getUserData = (()=>{
    if(document !=='' && document !== null && document !== undefined ){
      axios.get(url.GetUsers+document)
      .then(response => {
        setUserData(response.data.users)
        setDocument('')
        setUserModal(true)
      })
      .catch(ex=>{
        if (ex.response.data.error === 'USER_NOT_EXIST') {
          Swal.fire({title: '¡Error!',text: 'Este usuario no esta registrado',icon: 'error',confirmButtonText: 'Ok'
          })
        }
      })
    }
    else{
      Swal.fire({title: '¡Error!',text: 'Campos vacíos',icon: 'error',confirmButtonText: 'Ok'
      })
    }
  })

  const keyUpLabel=(event)=>{
    if (event.keyCode===13) {
      getLabel()
    }
  }

  const keyUpDocument=(event)=>{
    if (event.keyCode===13) {
      getUserData()
    }
  }

  return (
    <div className='w-[90%] lg:h-[25rem] h-[35rem] flex justify-between items-center flex-col gap-16'>
      <div className='w-[90%] h-[10rem] flex justify-center items-center border-b-4 border-gray-400' >
        <h3 className='ss:text-justify text-center text-[2rem]'>Consulta por tus bienes</h3>
      </div>
      <div className='w-[90%] h-[40rem] lg:flex-row gap-10 flex justify-between items-center flex-col'>
        <div className='sm:w-[23rem] w-[80%] h-[12rem] shadow-lg rounded flex flex-col items-center justify-center gap-3'>
          <div className='flex justify-center items-center gap-1 relative'>
            <input placeholder='Busca por número de placa' className='h-[3rem] md:w-[20rem]  w-full pl-[2.5rem] border-2 border-[#e7ebda] rounded box-border outline-none bg-[#f4f4f4] appearance-none'   onWheel={ event => event.currentTarget.blur() }  onChange={setEventToLabel} onKeyUp={(e=>keyUpLabel(e))} value={label} />
            <MagnifyingGlass className='absolute left-[0.75rem] top-[50%] translate-y-[-50%]' size={18} color={'#515151'}/>
          </div>
          <button className='btnConsultar' onClick={getLabel} >Consultar</button>
        </div>
        <div className='lg:flex hidden w-2 h-full border-r-4 border-gray-400 z-10'></div>
        <div className='sm:w-[23rem] w-[80%] h-[12rem] shadow-lg rounded flex flex-col items-center justify-center gap-3'>
          <div className='flex justify-center items-center gap-1 relative'>
            <input placeholder='Busca por número de cédula' className='h-[3rem] md:w-[20rem]  w-full pl-[2.5rem] border-2 border-[#e7ebda] rounded outline-none bg-[#f4f4f4] box-border appearance-none' onWheel={ event => event.currentTarget.blur() } onChange={setEventToDocument} onKeyUp={(e=>keyUpDocument(e))} value={document} />
            <MagnifyingGlass className='absolute left-[0.75rem] top-[50%] translate-y-[-50%]' size={18} color={'#515151'}/>
          </div>
          <button className='btnConsultar' onClick={getUserData}>Consultar</button>
        </div>
      </div>
        <div>
          <ModalHome data={labelData} onClose={setOpenModal}  visible={openModal}  />
          <UserModal data={userData} onClose={setUserModal} visible={userModal} />
        </div>
    </div>
    
  )
}