import React from 'react'
import { Dashboard } from '../../Layout/Dashboard/Dashboard';
import { GoodInformation } from '../GoodInformation/GoodInformation';
import { HeaderDashboard } from '../HeaderDashboard/HeaderDashboard';
import {XmarkCircle} from "@vectopus/atlas-icons-react";


export const ModalHome = ({data,visible,onClose}) => {
    
  const close=()=>{onClose(false)}

  if(!visible)return null;

  const sizeColumn = {
    column1 : "3rem",
    column2 : "7rem",
    column3 : "7rem",
    column4 : "7rem",
    column5 : "5rem",
    column6 : "5rem",
    column7 : "",
  }
  
  const headerDashboardInformation = {
    columnText1 : "Placa",
    columnText2 : "Elemento",
    columnText3 : "Descripción",
    columnText4 : "Atributos",
    columnText5 : "Valor",
    columnText6 : "Ubicación",
    columnWidth1 : sizeColumn.column1,
    columnWidth2 : sizeColumn.column2,
    columnWidth3 : sizeColumn.column3,
    columnWidth4 : sizeColumn.column4,
    columnWidth5 : sizeColumn.column5,
    columnWidth6 : sizeColumn.column6,
    columnWidth7 : sizeColumn.column7,
  }

  const goods = [{"labels" : data.labels, "element" : data.element, "classDescription" : data.classDescription, "atributtes" : data.atributtes, "purchasingValue" : data.purchasingValue, "currentLocation" : data.currentLocation},  ];

  return (
      <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex items-center justify-center z-10">
        <div className='ss:w-[55rem] w-[90%] h-[17rem] flex flex-col justify-evenly items-center rounded-[1rem] bg-[#ebecef]'>
          <div className='flex justify-end w-[90%]'>
          <XmarkCircle className='cursor-pointer' size={22} color={'#ff2825'} onClick={close}/>  
          </div>
          <div className='h-[45%] w-[100%] flex justify-center gap-[1rem] flex-col items-center'>
            <HeaderDashboard headerDashboardInformation={headerDashboardInformation} />
            <Dashboard dashboardInformation = {
              goods?.map((item, index) => 
                <GoodInformation  goodInformation={item} key={index} />
            )}/>
          </div>
        </div>
      </div>
    )
}
